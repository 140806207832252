import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import "./registrati.css";
import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import { Route } from 'react-router-dom';
import { Stack } from '@mui/system';
import Login from './login';
import Link from '@mui/material/Link';
import { red } from '@mui/material/colors';
import { configData } from '../configData/configData';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import logoValuetech from '../image/logoValuetech.png';

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
};

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: null,
      email: null,
      password: null,
      showPassword: false,
      exists: '',
      open: false,
      vertical: 'bottom',
      horizontal: 'center',
      errors: {
        fullName: '',
        email: '',
        password: '',
      }
    };
  }



  Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  handleClickPopUp = () => {
    this.setState({ ...this.state, open: true });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ ...this.state, open: false });
    document.location.href = configData.SITE_URL+"/login";
  };

  handleChange = (prop) => (event) => {
    // event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    //console.log(3);
    switch (name) {
      case 'fullName':
        errors.fullName =
          value.length < 5
            ? 'Lo username deve contenere almeno 5 caratteri'
            : ' ';
        break;
      case 'email':
        errors.email =
          validEmailRegex.test(value)
            ? ''
            : 'Email non valida!';
        break;
      case 'password':
        errors.password =
          value.length < 8
            ? 'Password deve avere almeno 8 caratteri '
            : '';
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  }


  handleClickShowPassword = () => {
    this.setState({
      ...this.state,
      showPassword: !this.state.showPassword,
    });
  };
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleSubmit = () => {
    //console.log(this.state);
    const utente = {
      username: this.state.userName,
      email: this.state.email,
      password: this.state.password
    }

    if (utente.username.length >= 5 && utente.password.length >= 8) {
      axios.post(configData.API_BASE + '/api/auth/signup', utente).then(
        res => {
          //console.log(res);
          //console.log(res.data);
          if (res.status == 200) {
            this.handleClickPopUp();
            
          }

        }

      ).catch(error => {
        //console.log(error);
        let errors = this.state.errors;
        if (error.response.status == 400) {
          if(error.response.data.message=="Questo Username è già presente nel sistema"){
            this.setState({ ...this.state, exists:error.response.data.message});
          } else if(error.response.data.message=="Questa E-mail è già presente nel sistema"){
            this.setState({ ...this.state, exists:error.response.data.message});
          } else {
            this.setState({...this.state, exists: error.response.data.message});
          }


        }
      })
    } else {
      console.log("Errore nella compilazione")
    }

    /*
      if(validateForm(this.state.errors)) {
        console.info('Valid Form')
      }else{
        console.error('Invalid Form')
      }
    */
  }

  handleUsername = (event) => {
    this.setState({ ...this.state, userName: event.target.value });
  }

  handlePassword = (event) => {
    this.setState({ ...this.state, password: event.target.value });
  }

  handleEmail = (event) => {
    this.setState({ ...this.state, email: event.target.value });
  }

  render() {
    const { errors } = this.state;
    return (
    <div>
     <div className='fill'>
     <img 
        width={'20%'}
        height={'20%'}
        style={{marginTop:"35px", marginLeft:"40%"}}
        src={logoValuetech}
        alt="Logo" />
     </div>
      <div className='wrapper'onSubmit={this.handleSubmit} style={{ margin:'-40px auto '}}>
        <div className='form-wrapper' style={{ margin:'50px auto'}}>
          <Typography gutterBottom variant="h5" component="div"  className="Controls" noValidate textAlign="center">
            Registrati
          </Typography>
          <pre></pre> 
            <div className='fullName'>

            <Box onChange={this.handleUsername}
             component="form"
             noValidate
             autoComplete="off">
              <TextField id="outlined-basic"
                        label="Username"
                        type='username'
                        name='fullName'
                        onChange={this.handleChange()}
                        value={this.state.username}
                        variant="outlined" noValidate/>
                        <div style ={{textAlign:"center",width: "100%" }}>
                        {errors.fullName.length > 0 && 
                        <span className='error'>{errors.fullName}</span>}
                        </div>
            </Box>
            </div>

            <div className='email'>
            <Box onChange={this.handleEmail}
             component="form"
             noValidate
             autoComplete="off">
              <TextField id="outlined-basic"
                        label="Email"
                        type='email'
                        name='email'
                        onChange={this.handleChange()}
                        value={this.state.email}
                        variant="outlined" noValidate/>
                        <div style ={{textAlign:"center",width: "100%" }}>
                        {errors.email.length > 0 && 
                        <span className='error'>{errors.email}</span>}
                        </div>
            </Box>
            </div>

            <div className='password'>
            <FormControl variant="outlined" onChange={this.handlePassword}>
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  label="Password"
                  id="outlined-adornment-password"
                  name="password"
                  type={this.state.showPassword ? 'text' : 'password'}
                  onChange={this.handleChange()}
                  value={this.state.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                        edge="end" >
                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              <div style ={{textAlign:"center",width: "100%" }}>
                {errors.password.length > 0 && 
                <span className='error'>{errors.password}</span>}
              </div>
            </FormControl>
            <pre></pre>  
            </div>
            <div className='submit' >
              <Button type="submit"  onClick={() => this.handleSubmit()} variant="contained" justifyContent="center" > Conferma </Button>
              <Snackbar open={this.state.open}  anchorOrigin={{vertical:'bottom',horizontal:'center'}}  autoHideDuration={2000} key={this.state.vertical + this.state.horizontal} onClose={this.handleClose}> 
            <Alert  onClose={this.handleClose} severity="success" sx={{ width: '100%' }}>
              Registrazione avvenuta con successo
            </Alert>
          </Snackbar>
            </div>
            <pre></pre>
            <div className="Color">
              {this.state.exists != "" ? this.state.exists : ""}
            </div>
        </div>
      </div>
    </div>
    );
  }
}