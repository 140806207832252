import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import "./login.css"
import axios from 'axios'
import { configData } from '../configData/configData';
import logoValuetech from '../image/logoValuetech.png';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {
  useParams
} from "react-router-dom";

export default function Login() {
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState("");
  const [repeatPassword, setRepeatPassword] = React.useState("");
  const [repeatPasswordError, setRepeatPasswordError] = React.useState("");
  const [showRepeatPassword, setShowRepeatPassword] = React.useState(false);
  const [alert, setAlert] = React.useState(false);
  const [enabled, setEnabled] = React.useState(true);

  let { token } = useParams();

  const handleShowPassword = (repeatPass) => {
    if (repeatPass) {
      setShowRepeatPassword(!showRepeatPassword);
    } else {
      setShowPassword(!showPassword);
    }
  }

  const onChangePassword = (event) => {
    console.log(event.target.value);
    setPassword(event.target.value);
    setPasswordError(event.target.value.length < 8 ? "La password deve avere almeno 8 caratteri" : "")
  }

  const onChangeRepeatPassword = (event) => {
    console.log(event.target.value);
    setRepeatPassword(event.target.value);
    setRepeatPasswordError(password !== event.target.value ? "Le password non coincidono" : "")
  }

  const onCloseAlert = () => {
    document.location.href = configData.SITE_URL+"/login";
  }

  const resetPassword = () => {
    if (passwordError !== "" || repeatPasswordError !== "") {
      return;
    }
      setEnabled(false);

      axios.post(configData.API_BASE + "/api/auth/changePassword",{
          "token":token,
          "newPassword": password
      }).then(res => {
          setAlert(true);
      });

    console.log(token);

  }

  return (
   <div>
    
    <div className='fill'>
     <img 
        width={'20%'}
        height={'20%'}
        style={{marginTop:"35px", marginLeft:"40%"}}
        src={logoValuetech}
        alt="Logo" />
     </div>
      <Card className ="Log" sx={{maxWidth:400}} 
        style={{
          boxShadow: "1px 3px 5px grey",
          borderRadius: "6px",
          padding: '1em',
          margin:'50px auto',
          textAlign:'center'}}  
      >
        <CardContent >
          <Typography gutterBottom variant="h5" component="div" >
            Reset Password
          </Typography>
          <FormControl sx={{ m: 1 }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Nuova Password</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              error={passwordError}
              type={showPassword ? 'text' : 'password'}
              value={password} 
              onChange={onChangePassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleShowPassword(false)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Nuova Password"
            />
            {passwordError !== "" ? <div className='error'>{passwordError}</div> : ""}
          </FormControl>
          <FormControl sx={{ m: 1 }}>
              <InputLabel htmlFor="outlined-adornment-repeat-password">Ripeti Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-repeat-password"
                label = "Ripeti Password"
                error={repeatPasswordError}
                type={showRepeatPassword ? 'text' : 'password'}
                value={repeatPassword} 
                onChange={onChangeRepeatPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleShowPassword(true)}
                      edge="end"
                    >
                      {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {repeatPasswordError !== "" ? <div className='error'>{repeatPasswordError}</div> : ""}
          </FormControl>
        </CardContent>
        <CardActions style={{justifyContent: "center"}}>
            <Button disabled={!enabled} type="submit" variant="contained" onClick={resetPassword}>Cambia Password</Button>
        </CardActions>
      </Card>

      <Snackbar open={alert}  anchorOrigin={{vertical:'bottom',horizontal:'center'}}  autoHideDuration={5000} onClose={onCloseAlert}> 
        <Alert severity="success" sx={{ width: '100%' }}>
          Password modificata con successo. Verrai reindirizzato alla pagina di login.
        </Alert>
      </Snackbar>
     </div> 
    );
}