import React, { useState } from 'react';
import { configData } from './configData/configData';
import axios from 'axios';
import {
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  AppBar,
  Toolbar,
  Card,
  CardContent,
  CardActions,
  IconButton,
  InputAdornment,
  colors,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormHelperText from "@material-ui/core/FormHelperText";
import { useEffect } from 'react';
import { Input } from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AttachFileIcon from '@mui/icons-material/AttachFile';

function RegistrationForm(props) {
  const id = props.id;
  const formData = props.formData;
  const setFormData = props.setFormData;
  const [nameTmpLe, setNameTmpLe] = useState([]);
  const [nameContratto, setNameContratto] = useState([]);
  const error = props.error;
  //const error2 = props.error2;
  const setError = props.setError;
  //const setError2 = props.setError2;
  const [cittaData, setCittaData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [hostingPartnerData, setHostingPartnerData] = useState([]);
  const [senderData, setSenderData] = useState([]);
  const [errorFile, setErrorFile] = useState({
    tmpContr : false,
    tmpLe : false
  }); 

  

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileContratto = (event) => {
    const file = event.target.files[0];
    //setFormData({...formData, templContratto: file})
    console.log("wsssw",file.name.split(".")[1])
    if (file != null){
      if(file.name.split(".")[1] == "docx"){
        setErrorFile({
          tmpContr : false
        })
        convertFileToBase64(file, function (base64String) {
          if (base64String) {
            setFormData({
                ...formData,
                templContratto : "data:"+ file.type+ ";name=" + file.name + ";" + "base64,"+base64String
            })
            //console.log("data:"+ file.type+ ";name=" + file.name + ";" + "base64,"+base64String);
            setNameContratto(file.name);
            //console.log(base64String);
          }else {
            console.log('Errore nella conversione del file in Base64');
          }
        });
      }else{
        setErrorFile({
          tmpContr : true
        })
      }
    }
  };

  function convertFileToBase64(file, callback) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const base64String = reader.result.split(',')[1];
      callback(base64String);
    };
    reader.onerror = function (error) {
      console.error('Errore nella lettura del file:', error);
      callback(null);
    };
  }

  const handleFileLA = (event) => {
    const file = event.target.files[0];
    //setFormData({...formData, templLE: file})
    //console.log("ciao",file)
    if (file != null){
      if(file.name.split(".")[1] == "docx"){
        setErrorFile({
          tmpLe : false
        })
        convertFileToBase64(file, function (base64String) {
            if (base64String) {
                setFormData({
                    ...formData,
                    templLE : "data:"+ file.type+ ";name=" + file.name + ";" + "base64,"+base64String
                })
                setNameTmpLe(file.name)
                console.log(base64String);
            } else {
                console.log('Errore nella conversione del file in Base64');
            }
        });
      }else{
        console.log("weee")
        setErrorFile({
          tmpLe : true
        })
      }
    }
  };

  
  const getCitta = () => {
    axios.get(configData.API_BASE+'/api/partners/citta')
      .then(response => {
        //console.log(response.data);
        setCittaData(response.data);
      })
      .catch(error => {
        console.error(error);
    });
  };


  const getProgettoApp = () => {
    axios.get(configData.API_BASE+'/api/project')
      .then(response => {
        //console.log(response.data);
        setProjectData(response.data);
      })
      .catch(error => {
        console.error(error);
    });
  };

  const getHostingPartner = () => {
    axios.get(configData.API_BASE+'/api/partners')
      .then(response => {
        //console.log(response.data);
        setHostingPartnerData(response.data);
      })
      .catch(error => {
        console.error(error);
    });
  };

  const getSender = () => {
    axios.get(configData.API_BASE+'/api/partners')
      .then(response => {
        //console.log(response.data);
        setSenderData(response.data);
      })
      .catch(error => {
        console.error(error);
    });
  };

  useEffect(() => {
    //console.log(formData)
    console.log(nameTmpLe)
    //console.log(formData.idSubProject);
    var token=localStorage.getItem('token');
  
    if(token){
      axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    }else{
      axios.defaults.headers.common['Authorization']=null;
    }
    getCitta();
    getProgettoApp();
    getHostingPartner();
    getSender();
  }, []);


  return (
    <Box>
      <Box display="flex" justifyContent="center" p={2}>
        <form>
          <Grid item xs={12} sm={6}>
            <strong>Dati Sotto-Progetto</strong>
          </Grid>
          <br></br>
          {(errorFile.tmpContr || errorFile.tmpLe) && (
          <br></br>
          )}
          <Grid container spacing={2}>
          {(errorFile.tmpContr || errorFile.tmpLe) && (
            <div style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              color: "red",
            }}>
            *Il file contratto/Learing Agreement deve essere di tipo "docx"
          </div>
          )}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>   
            <Box
                border={1}
                borderColor="lightgray"
                borderRadius={1.5}
                p={2}
            >
                <Button
                    variant="contained"
                    component="label"
                    >
                    Upload File L.A.
                    <UploadFileIcon></UploadFileIcon>
                    <Input
                        hidden
                        type="file"
                        onChange={handleFileLA}
                        error={error.templLE}
                        helperText={error.templLE ? 'Importa un file' : ''}
                    />
                </Button>
                {(formData.idSubProject != null && formData.idSubProject != "" && nameTmpLe.length == 0) ? formData.templLE ? <div>FileLA.docx<AttachFileIcon /></div> : null : formData.templLE ? <div>{nameTmpLe}<AttachFileIcon /></div> : null}
            </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
            <Box
                border={1}
                borderColor="lightgray"
                borderRadius={1.5}
                p={2}
            >
                <Button
                    variant="contained"
                    component="label"
                    >
                    Upload Contratto
                    <UploadFileIcon></UploadFileIcon>
                    <Input
                        hidden
                        type="file"
                        onChange={handleFileContratto}
                        error={error.templContratto}
                        helperText={error.templContratto ? 'Importa un file' : ''}
                    />
                </Button>
                {(formData.idSubProject != null && formData.idSubProject != "" && nameContratto.length == 0) ? formData.templContratto ? <div>FileContratto.docx <AttachFileIcon /></div>: null : formData.templContratto ? <div>{nameContratto} <AttachFileIcon /></div>: null}
            </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    name="inizioMobilita"
                    label="Data Inizio Mobilità"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    type="date"
                    value={formData.inizioMobilita}
                    onChange={handleChange}
                    fullWidth
                    error={error.inizioMobilita}
                    disabled ={formData.idSubProject != null ? true : false}
                    //error2 ={error2.inizioMobilita}
                    helperText={error.inizioMobilita ? "Seleziona una data per l'inzio della moblità" : ''}
                    required
                    sx={{ width: '100%' }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="fineMobilita"
                label="Data Fine Mobilità"
                InputLabelProps={{
                    shrink: true,
                }}
                type="date"
                value={formData.fineMobilita}
                onChange={handleChange}
                fullWidth
                error={error.fineMobilita}
                disabled ={formData.idSubProject != null ? true : false}
                helperText={error.fineMobilita ? 'Seleziona una data per la fine della moblità' : ''}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <InputLabel>Progetto di Appartenenza</InputLabel>
                    <Select
                    name="idProgAppartenenza"
                    value={formData.idProgAppartenenza}
                    onChange={handleChange}
                    error={error.idProgAppartenenza}
                    disabled ={formData.idSubProject != null ? true : false}
                    helperText={error.idProgAppartenenza ? 'Seleziona un progetto' : ''}
                    required
                    >
                    {projectData.map(project => (<MenuItem value={project.idProject}>{project.nomeProject}</MenuItem>) )}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <InputLabel>Hosting Partner</InputLabel>
                    <Select
                    name="idHostingPartner"
                    value={formData.idHostingPartner}
                    onChange={handleChange}
                    error={error.idHostingPartner}
                    helperText={error.idHostingPartner ? 'Seleziona un Hosting Partner' : ''}
                    required
                    >
                    {hostingPartnerData.map(hosting => (hosting.user.roles[0].id == 5 ? <MenuItem value={hosting.idPartner}>{hosting.nome}</MenuItem> : null))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <InputLabel>Sender</InputLabel>
                    <Select
                    name="idSender"
                    value={formData.idSender}
                    onChange={handleChange}
                    error={error.idSender}
                    disabled ={formData.idSubProject != null ? true : false}
                    helperText={error.idSender ? 'Seleziona un Sender' : ''}
                    required
                    >
                    {senderData.map(sender => (sender.user.roles[0].id == 4 ? <MenuItem value={sender.idPartner}>{sender.nome}</MenuItem> : null))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Città</InputLabel>
                <Select
                  name="cittaDest"
                  value={formData.cittaDest}
                  onChange={handleChange}
                  error={error.cittaDest}
                  disabled ={formData.idSubProject != null ? true : false}
                  helperText={error.cittaDest ? 'Seleziona una città' : ''}
                  required
                >
                  {cittaData.map(citta => (<MenuItem value={citta.idCitta}>{citta.descrizione}</MenuItem>) )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
}

export default RegistrationForm;
