import { configData } from './configData/configData';
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, AppBar, Toolbar, Grid, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, useTheme, DialogContentText, TableSortLabel } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import FormPartner from './FormPartner';
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import { formatMs } from '@material-ui/core';
import DownloadIcon from '@mui/icons-material/Download';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import {styled, alpha } from '@mui/material/styles';
import CustomizedMenus from './download';
import {useLocation, useParams} from "react-router-dom";
import XLSX from "sheetjs-style";
import * as fileSaver from "file-saver";



const columns = [
  { id: 'actions'},
  { id: 'nome', label: 'Nome', minWidth: 200 },
  { id: 'idPartner', label: 'ID', minWidth: 100 },
  { id: 'roles', label: 'Tipologia Partner', minWidth: 200 },
  { id: 'indirizzo', label: 'Indirizzo', minWidth: 200 },
  { id: 'cap', label: 'Cap', minWidth: 200 },
  { id: 'nazione', label: 'Nazionalità', minWidth: 200},
  { id: 'provincia', label: 'Provincia', minWidth: 200},
  { id: 'citta', label: 'Città', minWidth: 200},
  { id: 'codiceFiscale', label: 'Codice Fiscale', minWidth: 200},
  { id: 'telefono', label: 'Telefono', minWidth: 200},
  { id: 'oid', label: 'OID', minWidth: 200},
  { id: 'nomePartner', label: 'Nome Referente', minWidth: 200 },
  { id: 'cognomePartner', label: 'Cognome Referente', minWidth: 200 },
];

function createData(idPartner, nome, indirizzo, cap, nazione, provincia, citta, codiceFiscale, telefono, oid, nomePartner, cognomePartner, page, maxElementPage, roles) {
  return {
    idPartner,
    nome,
    indirizzo,
    cap,
    nazione,
    provincia,
    citta,
    codiceFiscale,
    telefono,
    oid,
    nomePartner,
    cognomePartner,
    page,
    maxElementPage,
    roles
  };
}


export default function ColumnGroupingTable() {
  //const [seleceterId, setSelecterId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteRowIndex, setDeleteRowIndex] = useState(null);
  const [deleteRowIndexMultiple, setDeleteRowIndexMultiple] = useState(null);
  const [rows, setRows] = useState([]);
  const [nazioniData, setNazioniData] = useState([]);
  const [provinciaData, setProvinciaData] = useState([]);
  const [cittaData, setCittaData] = useState([]);
  const [orderBy, setOrderBy] = useState("idPartner");
  const [orderDirection, setOrderDirection] = useState("desc");
  const [selected, setSelected] = React.useState([]);
  const [formData, setFormData] = useState({
    id: null,
    nome: '',
    indirizzo: '',
    cap: '',
    nazione: '',
    provincia: '',
    citta: '',
    codiceFiscale: '',
    telefono: '',
    oid: '',
    nomePartner: '',
    cognomePartner: '',
    email: '',
    username: '',
    password: '',
    roles: '',
  });

  const [error, setError] = useState({
    nome: false,
    indirizzo: false,
    cap: false,
    nazione: false,
    provincia: false,
    citta: false,
    codiceFiscale: false,
    telefono: false,
    oid: false,
    nomePartner: false,
    cognomePartner: false,
    email: false,
    username: false,
    password: false,
    roles : false
  });


  const theme = useTheme();

  const handleChangePage = (event, newPage) => {
    setFilterPartner({
      ...filterPartner,
      page: newPage,
    });
    filtra({
      nome: filterPartner.nome,
      indirizzo: filterPartner.indirizzo,
      cap: filterPartner.cap,
      nazione: filterPartner.nazione,
      provincia: filterPartner.provincia,
      citta: filterPartner.citta,
      codiceFiscale: filterPartner.codiceFiscale,
      telefono: filterPartner.telefono,
      oid: filterPartner.oid,
      nomePartner: filterPartner.nomePartner,
      cognomePartner: filterPartner.cognomePartner,
      page: newPage+1,
      maxElementPage: filterPartner.maxElementPage,
      orderColumn: orderBy,
      tipoOrder: orderDirection,
      roles : filterPartner.roles,
    });
  };

  const getNazioni = () => {
    axios.get(configData.API_BASE+'/api/partners/nazione')
      .then(response => {
        console.log(response.data);
        setNazioniData(response.data);
      })
      .catch(error => {
        console.error(error);
    });
  };

  const getProvincia = () => {
    axios.get(configData.API_BASE+'/api/partners/provincia')
      .then(response => {
        console.log(response.data);
        setProvinciaData(response.data);
      })
      .catch(error => {
        console.error(error);
    });
  };

  const getCitta = () => {
    axios.get(configData.API_BASE+'/api/partners/citta')
      .then(response => {
        console.log(response.data);
        setCittaData(response.data);
      })
      .catch(error => {
        console.error(error);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilterPartner({
      ...filterPartner,
      maxElementPage: event.target.value,
      page:0
    });
    filtra({
      nome: filterPartner.nome,
      indirizzo: filterPartner.indirizzo,
      cap: filterPartner.cap,
      nazione: filterPartner.nazione,
      provincia: filterPartner.provincia,
      citta: filterPartner.citta,
      codiceFiscale: filterPartner.codiceFiscale,
      telefono: filterPartner.telefono,
      oid: filterPartner.oid,
      nomePartner: filterPartner.nomePartner,
      cognomePartner: filterPartner.cognomePartner,
      page: 1,
      maxElementPage: event.target.value,
      orderColumn: orderBy,
      tipoOrder: orderDirection,
      roles : filterPartner.roles,
    });
  };


//inizio checkbox
const EnhancedTableToolbar = (props) => {
  const { numSelected,selezionati, state, onSelectAllClick, rowCount} = props;

  return (  
    <Toolbar
      sx={{
        pl: { sm: 0 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      <Checkbox
        color="primary"
        indeterminate={numSelected > 0 && numSelected < rowCount}
        checked={rowCount > 0 && numSelected === rowCount}
        onChange={onSelectAllClick}
        inputProps={{
          'aria-label': 'select all desserts',
        }}
        sx = {{
          position: 'sticky',
          //overflow: 'hidden',
          //textOverflow: 'ellipsis',
          //whiteSpace: 'nowrap',
          left: 0,
          zIndex: 1,
        }}
      />
      {numSelected > 0 ? (
        <Typography 
          color="inherit"
          variant="subtitle1"
          component="div"
          sx = {{
            position: 'sticky',
            //overflow: 'hidden',
            //textOverflow: 'ellipsis',
            //whiteSpace: 'nowrap',
            left: 50,
            zIndex: 1,
          }}
        >
          {numSelected} selezionato/i
          <Button variant="contained" onClick={exportSelected} sx={{left: 20}}><DownloadIcon></DownloadIcon>Download Selezionati</Button>
          <Button variant="contained" onClick={handleDeleteMultiple} color="error" sx={{left: 50}}>Elimina Selezionati
          <DeleteIcon/>
          </Button>
        </Typography>
      ) : (
        <Typography
          variant="h6"
          id="tableTitle"
          component="div"
          sx = {{
          position: 'sticky',
          //overflow: 'hidden',
          //textOverflow: 'ellipsis',
          //whiteSpace: 'nowrap',
          display : 'flex',
          left: 50,
          zIndex: 1,
        }}
        >
        <Button variant="outlined" onClick={handleNew} sx={{left: 10}}> 
          <AddIcon color="success"/>
          <strong>Nuovo partner</strong>
        </Button>
        <Button variant="contained" onClick={() => exportTable(listPartnerExport)} sx={{left: 30}}><DownloadIcon></DownloadIcon>Download</Button>
        
        </Typography>
      )}
    </Toolbar>
  
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const isSelected = (id) => selected.indexOf(id) !== -1;

const handleClick = (event, id) => {
  //console.log("sono qui");
  //console.log(id);
  const selectedIndex = selected.indexOf(id);
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected,id);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1),
    );
  }
  //console.log("Sono selezionati alcuni" + newSelected);
  setSelected(newSelected);
  
};


const handleSelectAllClick = (event, rowCount, selectedCount) => {
  if (event && (selectedCount != rowCount)) {
    const newSelected = rows.map((n) => n.idPartner);
    //console.log("sono selezionati " + newSelected);
    setSelected(newSelected);
    return;
  }
  setSelected([]);
  //console.log([]);
};

const handleDeleteMultiple = (selected) => {
  handleDeleteConfirmationMultiple(selected);
};

const handleDeleteConfirmationMultiple = (selected) => {
  setDeleteRowIndexMultiple(selected);
}

const handleDeleteCancelMultiple = () => {
  setDeleteRowIndexMultiple(null);
};

const handleDeleteSelected = () => {
  //console.log(selected)
    axios.delete(configData.API_BASE+'/api/partners/delete', {data : selected})
      .then(response => {
        //console.log(response.data);
        setSelected([]);
        applicaFiltri();
        setDeleteRowIndexMultiple(null);
      })
      .catch(error => {
        //console.error(error);
        alert("Errore imprevisto durante il processo di delete delle righe selezionate");
    });
};
//fine checkbox


  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDeleteCancel = () => {
    setDeleteRowIndex(null);
  };

	const handleNew = () =>{
		//setSelecterId(null);
    resetForm()
    setError({
      nome: false,
      indirizzo: false,
      cap: false,
      nazione: false,
      provincia: false,
      citta: false,
      codiceFiscale: false,
      telefono: false,
      oid: false,
      nomePartner: false,
      cognomePartner: false,
      email: false,
      username: false,
      password: false,
      roles : false
    });
		setOpenDialog(true);
	}

  const handleDeleteConfirm = () => {
    if (deleteRowIndex !== null) {
      const id = rows[deleteRowIndex].idPartner
      axios.delete(configData.API_BASE+'/api/partners/'+id)
        .then(response => {
          //console.log("ho eliminato il partner");
          filtra({
            nome: filterPartner.nome,
            indirizzo: filterPartner.indirizzo,
            cap: filterPartner.cap,
            nazione: filterPartner.nazione,
            provincia: filterPartner.provincia,
            citta: filterPartner.citta,
            codiceFiscale: filterPartner.codiceFiscale,
            telefono: filterPartner.telefono,
            oid: filterPartner.oid,
            nomePartner: filterPartner.nomePartner,
            cognomePartner: filterPartner.cognomePartner,
            page: filterPartner.page + 1,
            maxElementPage: filterPartner.maxElementPage,
            orderColumn: orderBy,
            tipoOrder: orderDirection,
            roles : filterPartner.roles
        });
        setDeleteRowIndex(null);
        })
        .catch(error => {
          //console.error(error);
          alert("Errore imprevisto durante il processo di delete");
      });
      //console.log(rows[deleteRowIndex].idPartner);
    }
  };

  const addAndCloseDialog = (body) => {
    //console.log(formData);
    if (formData.id == '') {
      if(
        formData.nome == "" || formData.nome == null ||
        formData.indirizzo == "" || formData.indirizzo == null ||
        formData.cap == "" || formData.cap == null ||
        formData.nazione == "" || formData.nazione == null ||
        formData.provincia == "" || formData.provincia == null ||
        formData.citta == "" || formData.citta == null ||
        formData.codiceFiscale == "" || formData.codiceFiscale == null ||
        formData.telefono == "" || formData.telefono == null ||
        formData.oid == "" || formData.oid == null ||
        formData.nomePartner == "" || formData.nomePartner == null ||
        formData.cognomePartner == "" || formData.cognomePartner == null ||
        formData.email == "" || formData.email == null ||
        formData.username == "" || formData.username == null ||
        formData.password == "" || formData.password == null ||
        formData.roles == "" || formData.roles == null
      ){
        setError({
          nome: formData.nome == "" || formData.nome == null,
          indirizzo: formData.indirizzo == "" || formData.indirizzo == null,
          cap: formData.cap == "" || formData.cap == null,
          nazione: formData.nazione == "" || formData.nazione == null,
          provincia: formData.provincia == "" || formData.provincia == null,
          citta: formData.citta == "" || formData.citta == null,
          codiceFiscale: formData.codiceFiscale == "" || formData.codiceFiscale == null,
          telefono: formData.telefono == "" || formData.telefono == null,
          oid: formData.oid == "" || formData.oid == null,
          nomePartner: formData.nomePartner == "" || formData.nomePartner == null,
          cognomePartner: formData.cognomePartner == "" || formData.cognomePartner == null,
          email: formData.email == "" || formData.email == null,
          username: formData.username == "" || formData.username == null,
          password: formData.password == "" || formData.password == null,
          roles : formData.roles == "" || formData.roles == null
        })
      }else{
        axios.post(configData.API_BASE+'/api/partners', formData)
        .then(response => {
          //console.log(response.data);
          applicaFiltri()
        })
        .catch(error => {
          if (error.response.status == 400 && error.response.data.id == null){
            alert("Utente già esistente");
          }
        });

        setError({
          nome: false,
          indirizzo: false,
          cap: false,
          nazione: false,
          provincia: false,
          citta: false,
          codiceFiscale: false,
          telefono: false,
          oid: false,
          nomePartner: false,
          cognomePartner: false,
          email: false,
          username: false,
          password: false,
          roles : false
        });
        setOpenDialog(false);
      }
    }
    else{
      if(
        formData.nome == "" || formData.nome == null ||
        formData.indirizzo == "" || formData.indirizzo == null ||
        formData.cap == "" || formData.cap == null ||
        formData.nazione == "" || formData.nazione == null ||
        formData.provincia == "" || formData.provincia == null ||
        formData.citta == "" || formData.citta == null ||
        formData.codiceFiscale == "" || formData.codiceFiscale == null ||
        formData.telefono == "" || formData.telefono == null ||
        formData.oid == "" || formData.oid == null ||
        formData.nomePartner == "" || formData.nomePartner == null ||
        formData.cognomePartner == "" || formData.cognomePartner == null ||
        formData.email == "" || formData.email == null ||
        formData.username == "" || formData.username == null ||
        formData.roles == "" || formData.roles == null
      ){
        setError({
          nome: formData.nome == "" || formData.nome == null,
          indirizzo: formData.indirizzo == "" || formData.indirizzo == null,
          cap: formData.cap == "" || formData.cap == null,
          nazione: formData.nazione == "" || formData.nazione == null,
          provincia: formData.provincia == "" || formData.provincia == null,
          citta: formData.citta == "" || formData.citta == null,
          codiceFiscale: formData.codiceFiscale == "" || formData.codiceFiscale == null,
          telefono: formData.telefono == "" || formData.telefono == null,
          oid: formData.oid == "" || formData.oid == null,
          nomePartner: formData.nomePartner == "" || formData.nomePartner == null,
          cognomePartner: formData.cognomePartner == "" || formData.cognomePartner == null,
          email: formData.email == "" || formData.email == null,
          username: formData.username == "" || formData.username == null,
          roles : formData.roles == "" || formData.roles == null
        })
      }else{
        console.log("wewe"+formData.password);
        axios.put(configData.API_BASE+'/api/partners/'+formData.id, formData)
        .then(response => {
          //console.log(response.data);
          applicaFiltri()
        })
        .catch(error => {
          console.error(error);
        });

        setError({
          nome: false,
          indirizzo: false,
          cap: false,
          nazione: false,
          provincia: false,
          citta: false,
          codiceFiscale: false,
          telefono: false,
          oid: false,
          nomePartner: false,
          cognomePartner: false,
          email: false,
          username: false,
          password: false,
          roles : false
        });
        setOpenDialog(false);
      }
    }
  };

  //filtri state
  const [filterPartner, setFilterPartner] = useState({
    nome: null,
    indirizzo: null,
    cap: null,
    nazione: '',
    provincia: '',
    citta: '',
    codiceFiscale: null,
    telefono: null,
    oid: null,
    nomePartner: null,
    cognomePartner: null,
    page: 0,
    maxElementPage: 10,
    roles: '',
    //id
  });

  //state partner
  const [listPartner, setListPartner] = useState({
    records: [],
    totalElement: 0
  });

  const handleChange = (event) => {
    setFilterPartner({
      ...filterPartner,
      [event.target.name]: event.target.value,
    });
  };

  const resetForm = () => {
    setFormData({
        id: "",
        nome: "",
        indirizzo: "",
        cap: "",
        nazione: "",
        provincia: "",
        citta: "",
        codiceFiscale: "",
        telefono: "",
        oid: "",
        nomePartner: "",
        cognomePartner: "",
        email: "",
        username: "",
        password: "",
        roles: "",
    });
  };


  const exportSelected = (body) => {
    //console.log(...selected)
    axios.post(configData.API_BASE+'/api/partners/download', selected)
      .then(response => {
        const righe = response.data.listPartner.map(item => ({"Nome" : item.nome, "Tipologia Partner" : item.user.roles[0].id == 4 ? "Scuola" : "Hosting Partner", 
        "Indirizzo" : item.indirizzo , "Cap" : item.cap, "Nazionalità" : item.nazione.descrizione, "Provincia" : item.provincia.descrizione, "Città" : item.citta.descrizione,
        "Codice Fiscale" : item.codiceFiscale, "Telefono" : item.telefono, "OID": item.oid, "Nome Referente" : item.nomePartner, "Cognome Referente": item.cognomePartner}))
        let jsonExcel= JSON.stringify(righe);
        const ws = XLSX.utils.json_to_sheet(JSON.parse(jsonExcel), {});

        if (righe.length > 0) {
          var temp = [];
          Object.keys(righe[0]).forEach(key => {
            const max_width = righe.reduce((w, r) => Math.max(w, r[key].length), 15);
            temp.push({ wch: max_width });
          });
          ws["!cols"] = temp;
        }

        const wb = {Sheets: {'data':ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, {bookType:'xlsx', type: 'array'});
        const data= new Blob ([excelBuffer],{type:fileTypeExcel});
        fileSaver.saveAs(data, 'Partner.xlsx');
      })
      .catch(error => {
        //console.error(error);
        alert("Errore imprevisto durante il processo di export");
    });
  };


  const [listPartnerExport, setListPartnerExport] = useState({
    records: [],
    totalElement: 0
  });

  
  const fileTypeExcel = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const exportTable = (body) => {
    axios.post(configData.API_BASE+'/api/partners/table', body)
    .then(response => {
      const righe = response.data.listPartner.map(item => ({"Nome" : item.nome, "Tipologia Partner" : item.user.roles[0].id == 4 ? "Scuola" : "Hosting Partner", 
        "Indirizzo" : item.indirizzo , "Cap" : item.cap, "Nazionalità" : item.nazione.descrizione, "Provincia" : item.provincia.descrizione, "Città" : item.citta.descrizione,
        "Codice Fiscale" : item.codiceFiscale, "Telefono" : item.telefono, "OID": item.oid, "Nome Referente" : item.nomePartner, "Cognome Referente": item.cognomePartner}))
      let jsonExcel= JSON.stringify(righe);
      const ws = XLSX.utils.json_to_sheet(JSON.parse(jsonExcel), {});

      if (righe.length > 0) {
        var temp = [];
        Object.keys(righe[0]).forEach(key => {
          const max_width = righe.reduce((w, r) => Math.max(w, r[key].length), 15);
          temp.push({ wch: max_width });
        });
        ws["!cols"] = temp;
      }

      const wb = {Sheets: {'data':ws}, SheetNames: ['data']};
      const excelBuffer = XLSX.write(wb, {bookType:'xlsx', type: 'array'});
      const data= new Blob ([excelBuffer],{type:fileTypeExcel});
      fileSaver.saveAs(data, 'Partner.xlsx');
    })
    .catch(error => {
      //console.error(error);
      alert("Errore imprevisto durante il processo di export");
    }); 
  };

  const filtra = (body) => {
    axios.post(configData.API_BASE+'/api/partners/table', body)
      .then(response => {
        setListPartner(response.data);
        setListPartnerExport({
          nome: filterPartner.nome,
          indirizzo: filterPartner.indirizzo,
          cap: filterPartner.cap,
          nazione: filterPartner.nazione,
          provincia: filterPartner.provincia,
          citta: filterPartner.citta,
          codiceFiscale: filterPartner.codiceFiscale,
          telefono: filterPartner.telefono,
          oid: filterPartner.oid,
          nomePartner: filterPartner.nomePartner,
          cognomePartner: filterPartner.cognomePartner,
          page: -1,
          maxElementPage: filterPartner.maxElementPage,
          orderColumn: orderBy,
          tipoOrder: orderDirection,
          roles : filterPartner.roles
        })

        var temp = [];
        for (let i = 0; i < response.data.listPartner.length; i++) {
          if (response.data.listPartner[i].user.roles[0].id == 4){
            temp.push(createData(response.data.listPartner[i].idPartner,response.data.listPartner[i].nome, response.data.listPartner[i].indirizzo, response.data.listPartner[i].cap, response.data.listPartner[i].nazione.descrizione, response.data.listPartner[i].provincia.descrizione, response.data.listPartner[i].citta.descrizione, response.data.listPartner[i].codiceFiscale, response.data.listPartner[i].telefono, response.data.listPartner[i].oid, response.data.listPartner[i].nomePartner, response.data.listPartner[i].cognomePartner, null, null, "Scuola"));
          }else if(response.data.listPartner[i].user.roles[0].id == 5){
            temp.push(createData(response.data.listPartner[i].idPartner,response.data.listPartner[i].nome, response.data.listPartner[i].indirizzo, response.data.listPartner[i].cap, response.data.listPartner[i].nazione.descrizione, response.data.listPartner[i].provincia.descrizione, response.data.listPartner[i].citta.descrizione, response.data.listPartner[i].codiceFiscale, response.data.listPartner[i].telefono, response.data.listPartner[i].oid, response.data.listPartner[i].nomePartner, response.data.listPartner[i].cognomePartner, null, null, "Hosting Partner"));
          }
          
        }
        setRows(temp);
        console.log(temp);
      })
      .catch(error => {
        console.error(error);
    });
  }

  useEffect(() => {
    var token=localStorage.getItem('token');
  
    if(token){
      axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    }else{
      axios.defaults.headers.common['Authorization']=null;
    }
    filtra({
      nome: filterPartner.nome,
      indirizzo: filterPartner.indirizzo,
      cap: filterPartner.cap,
      nazione: filterPartner.nazione,
      provincia: filterPartner.provincia,
      citta: filterPartner.citta,
      codiceFiscale: filterPartner.codiceFiscale,
      telefono: filterPartner.telefono,
      oid: filterPartner.oid,
      nomePartner: filterPartner.nomePartner,
      cognomePartner: filterPartner.cognomePartner,
      page: filterPartner.page + 1,
      maxElementPage: filterPartner.maxElementPage,
      orderColumn: orderBy,
      tipoOrder: orderDirection,
      roles : filterPartner.roles
    })

    getNazioni();
    getProvincia();
    getCitta();
  }, []);

  const buttonStyle = {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      alignSelf: 'flex-end',
    },
  };

  const handleEdit = (id) => {
		//setSelecterId(id);
    //console.log(id);
    
    axios.get(configData.API_BASE+'/api/partners/'+id)
    .then(response => {
      //console.log(response.data);
      //console.log(response.data.user.roles[0].id);
      setFormData({
        id: response.data.id,
        nome: response.data.nome,
        indirizzo: response.data.indirizzo,
        cap: response.data.cap,
        nazione: response.data.nazione.idNazione,
        provincia: response.data.provincia.idProvincia,
        citta: response.data.citta.idCitta,
        codiceFiscale: response.data.codiceFiscale,
        telefono: response.data.telefono,
        oid: response.data.oid,
        nomePartner: response.data.nomePartner,
        cognomePartner: response.data.cognomePartner,
        email: response.data.user.email,
        username: response.data.user.username,
        password: '',
        roles: [response.data.user.roles[0].name]
      });
    })
    .catch(error => {
      //console.error(error);
      alert("Errore imprevisto durante il processo di modifica del partner");
    });


		handleOpenDialog(true);
  };

	const handleDeleteConfirmation = (index) => {
    setDeleteRowIndex(index);
  }

	const handleDelete = (id) => {
    handleDeleteConfirmation(id);
  };

  const applicaFiltri = () => {
    setFilterPartner({
      ...filterPartner,
      page:0
    });
    filtra({
      nome: filterPartner.nome,
      indirizzo: filterPartner.indirizzo,
      cap: filterPartner.cap,
      nazione: filterPartner.nazione,
      provincia: filterPartner.provincia,
      citta: filterPartner.citta,
      codiceFiscale: filterPartner.codiceFiscale,
      telefono: filterPartner.telefono,
      oid: filterPartner.oid,
      nomePartner: filterPartner.nomePartner,
      cognomePartner: filterPartner.cognomePartner,
      page: 1,
      maxElementPage: filterPartner.maxElementPage,
      orderColumn: orderBy,
      tipoOrder: orderDirection,
      roles : filterPartner.roles
    });

    setListPartnerExport({
      nome: filterPartner.nome,
      indirizzo: filterPartner.indirizzo,
      cap: filterPartner.cap,
      nazione: filterPartner.nazione,
      provincia: filterPartner.provincia,
      citta: filterPartner.citta,
      codiceFiscale: filterPartner.codiceFiscale,
      telefono: filterPartner.telefono,
      oid: filterPartner.oid,
      nomePartner: filterPartner.nomePartner,
      cognomePartner: filterPartner.cognomePartner,
      page: -1,
      maxElementPage: filterPartner.maxElementPage,
      orderColumn: orderBy,
      tipoOrder: orderDirection,
      roles : filterPartner.roles
    })
  }

  const sort = (columnId, direction) => {
    setOrderBy(columnId);
    setOrderDirection(direction);
    setFilterPartner({...filterPartner, page:0})

    
    filtra({...filterPartner,
      page: 1,
      orderColumn : columnId,
      tipoOrder : direction});
  }

  return (
    <Box>
      <Box mb={4} />
      <Box ml={2} mr={2}>
        <div>
          <Box sx={{ display: 'flex', flexWrap: 'wrap'}}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <strong>Filtri di ricerca </strong>
                    <SearchIcon icon = "search"/>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                      <FormControl variant = "standard" sx={{ m: 0, minWidth: 195 }}>
                              <InputLabel>Tipologia Partner</InputLabel>
                              <Select
                              name="roles"
                              value={filterPartner.roles}
                              onChange={handleChange}
                              >
                              <MenuItem value="">Tutti</MenuItem>
                              <MenuItem value="ROLE_SCHOOL">Scuola</MenuItem>
                              <MenuItem value="ROLE_HOSTING_PARTNER">Hosting Partner</MenuItem>
                              </Select>
                          </FormControl>
                      </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField label="Nome" name="nome" variant="standard" value={filterPartner.nome} onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField label="Indirizzo" name = "indirizzo" variant="standard" value={filterPartner.indirizzo} onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField label="Cap" name="cap" variant="standard" value={filterPartner.cap} onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                    <FormControl variant = "standard" sx={{ m: 0, minWidth: 195 }}>
                            <InputLabel>Nazionalità</InputLabel>
                            <Select
                            name="nazione"
                            value={filterPartner.nazione}
                            onChange={handleChange}
                            >
                            <MenuItem value="">Tutti</MenuItem>
                            {nazioniData.map(nazione => (<MenuItem value={nazione.idNazione}>{nazione.descrizione}</MenuItem>) )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl variant = "standard" sx={{ m: 0, minWidth: 195 }}>
                            <InputLabel>Provincia</InputLabel>
                            <Select
                            name="provincia"
                            value={filterPartner.provincia}
                            onChange={handleChange}
                            >
                            <MenuItem value="">Tutti</MenuItem>
                            {provinciaData.map(provincia => (<MenuItem value={provincia.idProvincia}>{provincia.descrizione}</MenuItem>) )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                    <FormControl variant = "standard" sx={{ m: 0, minWidth: 195 }}>
                            <InputLabel>Citta</InputLabel>
                            <Select
                            name="citta"
                            value={filterPartner.citta}
                            onChange={handleChange}
                            >
                            <MenuItem value="">Tutti</MenuItem>
                            {cittaData.map(citta => (<MenuItem value={citta.idCitta}>{citta.descrizione}</MenuItem>) )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField label="Codice Fiscale" name="codiceFiscale" variant="standard" value={filterPartner.codiceFiscale} onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField label="Telefono" name="telefono" variant="standard" value={filterPartner.telefono} onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField label="OID" name="oid" variant="standard" value={filterPartner.oid} onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField label="Nome Referente" name="nomePartner" variant="standard" value={filterPartner.nomePartner} onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField label="Cognome Referente" name="cognomePartner" variant="standard" value={filterPartner.cognomePartner} onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={12}>
                      <br></br>
                      <Button variant="contained" color="primary" onClick={applicaFiltri}>
                        Filtra
                      </Button>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
          </Box>
        </div>

        <Box mb={4}/>
        <Paper sx={{ width: '100%' }}>
          <TableContainer  style={{maxHeight: "60vh"}}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead style={{position:"sticky", top:0, zIndex:99, backgroundColor: "white"}}>
                <TableRow>
                  <TableCell align="left" colSpan={100}>
                    <EnhancedTableToolbar rowCount={rows.length} onSelectAllClick={() => handleSelectAllClick(rows, rows.length, selected.length)} handleChange={handleChange} numSelected={selected.length} selezionati={selected}></EnhancedTableToolbar> 
                  </TableCell>
                  <TableCell align="right">
                  {/* pulsante export*/}
                  </TableCell>
                </TableRow>
                <TableRow>
                {columns.map((column) => (
                  <TableCell
                    sx={{
                      cursor: column.id !== 'action' ? 'pointer' : 'default',
                      position: 'sticky',
                      left: column.id === 'nome' ? 0 : 'auto',
                      zIndex: column.id === 'nome' ? 1 : 'auto',
                    }}
                    key={column.id}
                    align={column.align}
                    style={{
                      top: 57,
                      minWidth: column.minWidth,
                      backgroundColor: column.id !== 'nome' ? 'inherit' : 'white',
                    }}
                    onClick={() => {
                      if (column.id !== 'roles') {
                        sort(column.id, orderDirection === 'asc' ? 'desc' : 'asc');
                      }
                    }}
                  >
                    <strong>{column.label}</strong>
                    {column.id !== 'roles' && column.id !== 'actions' ? (
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? orderDirection : 'asc'}
                      />
                    ) : null}
                  </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.idPartner);
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.id} aria-checked={isItemSelected} selected={isItemSelected}>
                        {columns.map((column) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          const value = row[column.id];
                          if (column.id === 'nome') { // Condizione per la colonna 'nome'
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                sx={{
                                  cursor: 'default', // Imposta il cursore sulla colonna 'nome'
                                  position: 'sticky',
                                  //overflow: 'hidden',
                                  //textOverflow: 'ellipsis',
                                  //whiteSpace: 'nowrap',
                                  left: 0,
                                  zIndex: 1,
                                  backgroundColor : 'white'
                                }}
                              >
                                {value}
                              </TableCell>
                            );
                          }
                          if (column.id === 'actions') {
                            return (
                                <TableCell key={column.id}>
                                    <div style={{ display: 'flex'}}>
                                    <Checkbox
                                      color="primary"
                                      checked={isItemSelected}
                                      onClick={(event) => handleClick(event, row.idPartner)}
                                      inputProps={{
                                        'aria-labelledby': labelId,
                                      }}
                                    />
                                    <Button onClick={() => handleEdit(row.idPartner)} size="small" style={{marginLeft : '0px'}}>
                                    <EditIcon icon="edit" color="blue" />
                                    </Button>
                                    <Button onClick={() => handleDelete(index)} size="small" style={{marginLeft : '0px'}}>
                                    <DeleteIcon color="error"/>
                                    </Button>
                                    </div>
                                </TableCell>
                            );
                          }
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number' ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 50]}
            component="div"
            count={listPartner.countPartner}
            //rowsPerPage={rowsPerPage}
            //page={page}
            rowsPerPage={filterPartner.maxElementPage}
            page={filterPartner.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        {(formData.id == null || formData.id == '') ? <DialogTitle>Aggiungi un Partner</DialogTitle> : <DialogTitle>Modifica un Partner</DialogTitle>}
        <DialogContent>
            <FormPartner formData={formData} setFormData = {setFormData} error = {error} setError = {setError} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annulla</Button>
          <Button onClick={addAndCloseDialog} color="primary">
            Salva
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteRowIndex !== null} onClose={handleDeleteCancel}>
        <DialogTitle>Elimina riga</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sei sicuro di voler eliminare questa riga?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Annulla
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Conferma
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={deleteRowIndexMultiple !== null} onClose={handleDeleteCancelMultiple}>
        <DialogTitle>Elimina le righe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sei sicuro di voler eliminare le righe selezionate?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancelMultiple} color="primary">
            Annulla
          </Button>
          <Button onClick={handleDeleteSelected} color="primary">
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
