import React, { useState } from 'react';
import { configData } from './configData/configData';
import axios from 'axios';
import {
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  AppBar,
  Toolbar,
  Card,
  CardContent,
  CardActions,
  IconButton,
  InputAdornment,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormHelperText from "@material-ui/core/FormHelperText";
import { useEffect } from 'react';

function RegistrationForm(props) {
  const id = props.id;
  const formData = props.formData;
  const setFormData = props.setFormData;
  const error = props.error;
  const setError = props.setError;
  const [showPassword, setShowPassword] = useState(false);
  const [nazioniData, setNazioniData] = useState([]);
  const [provinciaData, setProvinciaData] = useState([]);
  const [cittaData, setCittaData] = useState([]);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeNazione = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
      provincia : '',
      citta : ''
    });
    getProvincia(event.target.value);
  };

  const handleChangeProvincia = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
      citta : ''
    });
    getCitta(event.target.value);
  };

  const handleChangeRuolo = (event) => {
    setFormData({
      ...formData,
      roles: [event.target.value],
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getNazioni = () => {
    axios.get(configData.API_BASE+'/api/partners/nazione')
      .then(response => {
        //console.log(response.data);
        setNazioniData(response.data);
      })
      .catch(error => {
        console.error(error);
    });
  };

  const getProvincia = (nazione) => {
    //console.log("wewewewe",nazione[0].idNazione)
    axios.get(configData.API_BASE+'/api/partners/provincia/'+nazione)
      .then(response => {
        //console.log(response.data);
        setProvinciaData(response.data);
      })
      .catch(error => {
        console.error(error);
    });
  };

  const getCitta = (provincia) => {
    axios.get(configData.API_BASE+'/api/partners/citta/'+provincia)
      .then(response => {
        //console.log(response.data);
        setCittaData(response.data);
      })
      .catch(error => {
        console.error(error);
    });
  };

  useEffect(() => {
    var token=localStorage.getItem('token');
  
    if(token){
      axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    }else{
      axios.defaults.headers.common['Authorization']=null;
    }
    getNazioni();
  }, []);


  return (
    <Box>
      <Box display="flex" justifyContent="center" p={2}>
        <form>
          <Grid item xs={12} sm={6}>
            <strong>Dati Partner</strong>
          </Grid>
          <br></br>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Tipologia Partner</InputLabel>
                <Select
                  name="roles"
                  value={formData.roles}
                  onChange={handleChangeRuolo}
                  error={error.roles}
                  helperText={error.roles ? 'Seleziona una tipologia di partner' : ''}
                  required
                >
                  <MenuItem value="ROLE_SCHOOL">Scuola</MenuItem>
                  <MenuItem value="ROLE_HOSTING_PARTNER">Hosting Partner</MenuItem>
                  {/* Altri elementi del menu per la tipologia partner */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="nome"
                label="Nome"
                value={formData.nome}
                onChange={handleChange}
                fullWidth
                error={error.nome}
                helperText={error.nome ? 'Inserisci un nome' : ''}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="indirizzo"
                label="Indirizzo"
                value={formData.indirizzo}
                onChange={handleChange}
                fullWidth
                error={error.indirizzo}
                helperText={error.indirizzo ? 'Inserisci un indirizzo' : ''}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="cap"
                label="CAP"
                value={formData.cap}
                onChange={handleChange}
                fullWidth
                error={error.cap}
                helperText={error.cap ? 'Inserisci un CAP' : ''}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Nazione</InputLabel>
                <Select
                  name="nazione"
                  value={formData.nazione}
                  onChange={handleChangeNazione}
                  error={error.nazione}
                  helperText={error.nazione ? 'Seleziona una nazione' : ''}
                  required
                >
                  {nazioniData.map(nazione => (<MenuItem value={nazione.idNazione}>{nazione.descrizione}</MenuItem>) )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Provincia</InputLabel>
                <Select
                  name="provincia"
                  value={formData.provincia}
                  onChange={handleChangeProvincia}
                  error={error.provincia}
                  helperText={error.provincia ? 'Seleziona una provincia' : ''}
                  required
                  disabled = {formData.nazione == '' ? true : false}
                >
                  {provinciaData.map(provincia => (<MenuItem value={provincia.idProvincia}>{provincia.descrizione}</MenuItem>) )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Città</InputLabel>
                <Select
                  name="citta"
                  value={formData.citta}
                  onChange={handleChange}
                  error={error.citta}
                  helperText={error.citta ? 'Seleziona una città' : ''}
                  required
                  disabled = {formData.provincia == '' ? true : false}
                >
                  {cittaData.map(citta => (<MenuItem value={citta.idCitta}>{citta.descrizione}</MenuItem>) )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="codiceFiscale"
                label="Codice Fiscale o Partita Iva"
                value={formData.codiceFiscale}
                onChange={handleChange}
                fullWidth
                error={error.codiceFiscale}
                helperText={error.codiceFiscale ? 'Inserisci un codice fiscale/partita iva': ''}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="telefono"
                label="Telefono"
                value={formData.telefono}
                onChange={handleChange}
                fullWidth
                error={error.telefono}
                helperText={error.telefono ? 'Inserisci un numero di telefono' : ''}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="oid"
                label="OID"
                value={formData.oid}
                onChange={handleChange}
                fullWidth
                error={error.oid}
                helperText={error.oid ? 'Inserisci un OID' : ''}
                required
              />
            </Grid>
          </Grid>
          
          <Grid item xs={12} sm={6} style = {{marginTop : '30px'}}>
            <strong>Dati Referente</strong>
          </Grid>
          <br></br>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="nomePartner"
                label="Nome"
                value={formData.nomePartner}
                onChange={handleChange}
                fullWidth
                error={error.nomePartner}
                helperText={error.nomePartner ? 'Inserisci un Nome del Partner' : ''}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="cognomePartner"
                label="Cognome"
                value={formData.cognomePartner}
                onChange={handleChange}
                fullWidth
                error={error.cognomePartner}
                helperText={error.cognomePartner ? 'Inserisci un Cognome del Partner' : ''}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email"
                label="Email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                error={error.email}
                helperText={error.email ? 'Inserisci un Indirizzo Email' : ''}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="username"
                label="Username"
                value={formData.username}
                onChange={handleChange}
                fullWidth
                error={error.username}
                helperText={error.username ? 'Inserisci un Username Partner' : ''}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={formData.password}
                  onChange={handleChange}
                  fullWidth
                  error={error.password}
                  helperText={error.password ? 'Inserisci una Password' : ''}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
}

export default RegistrationForm;
