import React, { Component } from "react";
import axios from "axios";
import {configData} from "./configData/configData";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {CardActions, Grid} from "@mui/material";
import './ListaForm.css';
import ScegliBottoneListaForm from "./ScegliBottoneListaForm";
import logoValuetech from './image/logoValuetech.png';
import "./font/Ubuntu-Bold.ttf";
import "./font/Ubuntu-Light.ttf";
import {Backdrop, CircularProgress} from "@mui/material";

axios.defaults.headers.common['Authorization']=localStorage.getItem('token');
axios.defaults.headers.common['Content-Type']=localStorage.getItem('id');

class ListaForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dati: [],
            bottone: [], //false "compila", true "visualizza"
            idUser:localStorage.getItem('id'),
            loading:true,
        }
    }

    loadData(){
        var token=localStorage.getItem('token');
        if(token){
            axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
            axios.get(configData.API_BASE+"/api/forms/").then(
                res=>{
                    let formCompilato=[];
                    res.data.map((form) => (
                        axios.get(configData.API_BASE+"/api/forms/submit/risposta/"+form.id+"/"+localStorage.getItem('id')+"/exist").then(
                            button=>{
                                formCompilato.push({id: form.id, b: button.data});
                                this.setState(state => {
                                    const list = state.bottone.push({id: form.id, b: button.data});
                                    return {
                                        value: '',
                                    };
                                });
                            })
                    ))
                    this.setState({...this.state,dati:res.data, loading:false});
                }
            )
        }else{
            axios.defaults.headers.common['Authorization']=null;
        }
    }

    componentDidMount() {
        this.loadData();
    }

    render(){
        return(
            <div>

                <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                    <img src={logoValuetech} width={'20%'} height={'20%'} style={{marginTop:"35px"}}/>
                    <h1 style={{marginTop:'20px', fontFamily:'Ubuntu-Black', color:'#1d659c'}}>Lista Form Disponibili</h1>
                {this.state.dati.map((form) => (
                    <Grid item md={2.4}>
                        <Card className='card' style={{fontFamily:'Ubuntu-Light'}}>
                            <CardContent>
                                <b >{form.nomeProgetto}</b>
                            </CardContent>
                            <CardActions style={{justifyContent: 'center'}}>
                                <ScegliBottoneListaForm formId={form.id} isTrue={this.state.bottone.find(t=>{if(t.id==form.id){return t.b}})} />
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
                    <Backdrop sx={{color:'#ffffff', zIndex:999}} open={this.state.loading} ><CircularProgress color="inherit"/></Backdrop>
                </Grid>
            </div>
        );
    }
}

export default ListaForm;