import { configData } from './configData/configData';
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, AppBar, Toolbar, Grid, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, useTheme, DialogContentText, TableSortLabel, linkClasses } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import { formatMs } from '@material-ui/core';
import DownloadIcon from '@mui/icons-material/Download';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import {styled, alpha } from '@mui/material/styles';
import CustomizedMenus from './download';
import {useLocation, useParams} from "react-router-dom";
import XLSX from "sheetjs-style";
import * as fileSaver from "file-saver";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import FormProject from './FormProject';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import {Routes, Route, useNavigate} from 'react-router-dom';




const columns = [
  { id: 'actions'},
  { id: 'cup', label: 'CUP', minWidth: 200 },
  { id: 'idProject', label: 'ID', minWidth: 75 },
  { id: 'nomeProject', label: 'Nome Progetto', minWidth: 200 },
  { id: 'anno', label: 'Anno Progetto', minWidth: 200 },
  { id: 'attivo', label: 'Stato Progetto', minWidth: 200 },
  
  { id: 'codiceProject', label: 'Codice Progetto', minWidth: 200 },
  { id: 'referenteProject', label: 'Referente Progetto', minWidth: 200 },
];


function createData(idProject, nomeProject, anno, attivo, cup, codiceProject, referenteProject) {
  return {
    idProject,
    nomeProject,
    anno,
    attivo,
    cup,
    codiceProject,
    referenteProject
  };
}


export default function ColumnGroupingTable() {
  //const [seleceterId, setSelecterId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteRowIndex, setDeleteRowIndex] = useState(null);
  const [deleteRowIndexMultiple, setDeleteRowIndexMultiple] = useState(null);
  const [rows, setRows] = useState([]);
  const [orderBy, setOrderBy] = useState("idProject");
  const [orderDirection, setOrderDirection] = useState("desc");
  const [selected, setSelected] = React.useState([]);
  const [dataFromProject, setDataFromProject] = useState({
    idProject: null,
  });
  const [formProject, setFormProject] = useState({
    idProject: null,
    nomeProject : "",
    paesi : "",
    anno : "",
    attivo : false,
    cup : "",
    codiceProject : "",
    referenteProject : ""
  });

  const [error, setError] = useState({
    idProject: false,
    nomeProject : false,
    anno : false,
    cup : false,
    codiceProject : false,
    referenteProject : false
  });


  const theme = useTheme();

  const handleChangePage = (event, newPage) => {
    setFilterProject({
      ...filterProject,
      page: newPage,
    });
    filtra({
      idProject : filterProject.idProject,
      nomeProject: filterProject.nomeProject,
      anno: filterProject.anno,
      attivo: filterProject.attivo,
      cup : filterProject.cup,
      codiceProject : filterProject.codiceProject,
      referenteProject : filterProject.referenteProject,
      page: newPage+1,
      maxElementPage: filterProject.maxElementPage,
      orderColumn: orderBy,
      tipoOrder: orderDirection,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilterProject({
      ...filterProject,
      maxElementPage: event.target.value,
      page:0
    });
    filtra({
      idProject : filterProject.idProject,
      nomeProject: filterProject.nomeProject,
      anno: filterProject.anno,
      attivo: filterProject.attivo,
      cup : filterProject.cup,
      codiceProject : filterProject.codiceProject,
      referenteProject : filterProject.referenteProject,
      page: 1,
      maxElementPage: event.target.value,
      orderColumn: orderBy,
      tipoOrder: orderDirection,
    });
  };


//inizio checkbox
const EnhancedTableToolbar = (props) => {
  const { numSelected,selezionati, state, onSelectAllClick, rowCount} = props;

  return (  
    <Toolbar
      sx={{
        pl: { sm: 0 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      <Checkbox
        color="primary"
        indeterminate={numSelected > 0 && numSelected < rowCount}
        checked={rowCount > 0 && numSelected === rowCount}
        onChange={onSelectAllClick}
        inputProps={{
          'aria-label': 'select all desserts',
        }}
        sx = {{
          position: 'sticky',
          //overflow: 'hidden',
          //textOverflow: 'ellipsis',
          //whiteSpace: 'nowrap',
          left: 0,
          zIndex: 1,
        }}
      />
      {numSelected > 0 ? (
        <Typography 
          color="inherit"
          variant="subtitle1"
          component="div"
          sx = {{
            position: 'sticky',
            //overflow: 'hidden',
            //textOverflow: 'ellipsis',
            //whiteSpace: 'nowrap',
            left: 50,
            zIndex: 1,
          }}
        >
          {numSelected} selezionato/i
          <Button variant="contained" onClick={exportSelected} sx={{left: 20}}><DownloadIcon></DownloadIcon>Download Selezionati</Button>
          <Button variant="contained" onClick={handleDeleteMultiple} color="error" sx={{left: 50}}>Elimina Selezionati
          <DeleteIcon/>
          </Button>
        </Typography>
      ) : (
        <Typography
          variant="h6"
          id="tableTitle"
          component="div"
          sx = {{
          position: 'sticky',
          //overflow: 'hidden',
          //textOverflow: 'ellipsis',
          //whiteSpace: 'nowrap',
          display : 'flex',
          left: 50,
          zIndex: 1,
        }}
        >
        <Button variant="outlined" onClick={handleNew} sx={{left: 10}}> 
          <AddIcon color="success"/>
          <strong>Nuovo Progetto</strong>
        </Button>
        <Button variant="contained" onClick={() => exportTable(listProjectExport)} sx={{left: 30}}><DownloadIcon></DownloadIcon>Download</Button>
        
        </Typography>
      )}
    </Toolbar>
  
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const isSelected = (id) => selected.indexOf(id) !== -1;

const handleClick = (event, id) => {
  //console.log("sono qui");
  //console.log(id);
  const selectedIndex = selected.indexOf(id);
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected,id);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1),
    );
  }
  //console.log("Sono selezionati alcuni" + newSelected);
  setSelected(newSelected);
  
};


const handleSelectAllClick = (event, rowCount, selectedCount) => {
  if (event && (selectedCount != rowCount)) {
    const newSelected = rows.map((n) => n.idProject);
    //console.log("sono selezionati " + newSelected);
    setSelected(newSelected);
    return;
  }
  setSelected([]);
  //console.log([]);
};

const handleDeleteMultiple = (selected) => {
  handleDeleteConfirmationMultiple(selected);
};

const handleDeleteConfirmationMultiple = (selected) => {
  setDeleteRowIndexMultiple(selected);
}

const handleDeleteCancelMultiple = () => {
  setDeleteRowIndexMultiple(null);
};

const handleDeleteSelected = () => {
  //console.log(selected)
    axios.delete(configData.API_BASE+'/api/project/delete', {data : selected})
      .then(response => {
        //console.log(response.data);
        setSelected([]);
        applicaFiltri();
        setDeleteRowIndexMultiple(null);
      })
      .catch(error => {
        //console.error(error);
        alert("Errore imprevisto durante il processo di delete delle righe selezionate");
    });
};
//fine checkbox


  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDeleteCancel = () => {
    setDeleteRowIndex(null);
  };

const handleNew = () =>{
    resetForm()
    setFormProject({
        idProject : "",
        attivo : false,
        nomeProject : "",
        anno : "",
        cup : "",
        codiceProject : "",
        referenteProject : ""
    })
    setError({
        nomeProject : false,
        anno : false,
        cup : false,
        codiceProject : false,
        referenteProject : false
    });
    setOpenDialog(true);
}

  const handleDeleteConfirm = () => {
    if (deleteRowIndex !== null) {
      const id = rows[deleteRowIndex].idProject
      axios.delete(configData.API_BASE+'/api/project/'+id)
        .then(response => {
          //console.log("ho eliminato il partner");
          filtra({
            idProject : filterProject.idProject,
            nomeProject: filterProject.nomeProject,
            anno: filterProject.anno,
            attivo: filterProject.attivo,
            cup : filterProject.cup,
            codiceProject : filterProject.codiceProject,
            referenteProject : filterProject.referenteProject,
            page: filterProject.page + 1,
            maxElementPage: filterProject.maxElementPage,
            orderColumn: orderBy,
            tipoOrder: orderDirection,
        });
        setDeleteRowIndex(null);
        })
        .catch(error => {
          //console.error(error);
          alert("Errore imprevisto durante il processo di delete delle righe selezionate");
      });
      //console.log(rows[deleteRowIndex].idProject);
    }
  };

  const addAndCloseDialog = (body) => {
    if (formProject.idProject == '' || formProject.idProject == null) {
        if( formProject.nomeProject == "" || formProject.nomeProject == null || formProject.anno == "" || formProject.anno == null 
            || formProject.cup == "" || formProject.cup == null || formProject.codiceProject == "" || formProject.codiceProject == null
            || formProject.referenteProject == "" || formProject.referenteProject == null){
            setError({
                nomeProject: formProject.nomeProject == "" || formProject.nomeProject == null,
                anno: formProject.anno == "" || formProject.anno == null,
                cup : formProject.cup == "" || formProject.cup == null,
                codiceProject : formProject.codiceProject == "" || formProject.codiceProject == null,
                referenteProject : formProject.referenteProject == "" || formProject.referenteProject == null
            })
        }else{
            axios.post(configData.API_BASE+'/api/project', formProject)
            .then(response => {
              //console.log(response.data);
              applicaFiltri()
            })
            .catch(error => {
                //console.log(error.response.data.idProject);
              // if (error.response.status == 400 && error.response.data.idProject == null){
              //   alert("Progetto già esistente");
              // }
              alert(error.response.data);
            });

            setError({
                nomeProject: false,
                anno: false,
                attivo: false,
                cup : false,
                codiceProject : false,
                referenteProject : false
            });
            setOpenDialog(false);
        }
    }else{
        if( formProject.nomeProject == "" || formProject.nomeProject == null || formProject.anno == "" || formProject.anno == null
            || formProject.cup == "" || formProject.cup == null || formProject.codiceProject == "" || formProject.codiceProject == null
            || formProject.referenteProject == "" || formProject.referenteProject == null){
            setError({
                nomeProject: formProject.nomeProject == "" || formProject.nomeProject == null,
                anno: formProject.anno == "" || formProject.anno == null,
                cup : formProject.cup == "" || formProject.cup == null,
                codiceProject : formProject.codiceProject == "" || formProject.codiceProject == null,
                referenteProject : formProject.referenteProject == "" || formProject.referenteProject == null
            })
        }else{
            //rendi modificabile
            axios.put(configData.API_BASE+'/api/project/'+formProject.idProject, formProject)
            .then(response => {
                applicaFiltri();
            })
            .catch(error => {
                //console.error(error);
                alert(error.response.data);
            });

            setError({
                nomeProject: false,
                anno: false,
                attivo: false,
                cup : false,
                codiceProject : false,
                referenteProject : false
            });
            setOpenDialog(false);
        }
    }
  };

  //filtri state
  const [filterProject, setFilterProject] = useState({
    idProject : null,
    nomeProject: null,
    anno: null,
    attivo: null,
    cup : null,
    codiceProject : null,
    referenteProject : null,
    page: 0,
    maxElementPage: 10,
  });

  //state partner
  const [listProject, setListProject] = useState({
    records: [],
    totalElement: 0
  });

  const handleChange = (event) => {
    //console.log(event.target.name, event.target.value,)
    setFilterProject({
      ...filterProject,
      [event.target.name]: event.target.value,
    });
  };

  const resetForm = () => {
    setFormProject({
        idProject: "",
        nomeProject: "",
        anno : "",
        attivo: "",
        cup : "",
        codiceProject : "",
        referenteProject : ""
    });
  };

  const exportSelected = (body) => {
    //console.log(...selected)
    axios.post(configData.API_BASE+'/api/project/download', selected)
      .then(response => {
        const righe = response.data.listProject.map(item => ({"CUP": item.cup, "Nome Progetto" : item.nomeProject, "Anno Progetto" : item.anno, "Stato Progetto" : item.attivo == true ? "ATTIVO" : "NON ATTIVO", "Codice Progetto" : item.codiceProject, "Referente Progetto" : item.referenteProject}));
        let jsonExcel= JSON.stringify(righe);
        const ws = XLSX.utils.json_to_sheet(JSON.parse(jsonExcel));

        if (righe.length > 0) {
          var temp = [];
          Object.keys(righe[0]).forEach(key => {
            const max_width = righe.reduce((w, r) => Math.max(w, r[key].length), 20);
            temp.push({ wch: max_width });
          });
          ws["!cols"] = temp;
        }

        const wb = {Sheets: {'data':ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, {bookType:'xlsx', type: 'array'});
        const data= new Blob ([excelBuffer],{type:fileTypeExcel});
        fileSaver.saveAs(data, 'Progetti.xlsx');
      })
      .catch(error => {
        //console.error(error);
        alert("Errore imprevisto durante il processo di export");
    });
  };


  const [listProjectExport, setListProjectExport] = useState({
    records: [],
    totalElement: 0
  });

  
  const fileTypeExcel = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const exportTable = (body) => {
    axios.post(configData.API_BASE+'/api/project/table', body)
    .then(response => {
      console.log(body)
      const righe = response.data.listProject.map(item => ({"CUP" : item.cup, "Nome Progetto" : item.nomeProject, "Anno" : item.anno, "Stato Progetto" : item.attivo == true ? "ATTIVO" : "NON ATTIVO", "Codice Progetto" : item.codiceProject, "Referente Progetto" : item.referenteProject}));
      let jsonExcel= JSON.stringify(righe);
      const ws = XLSX.utils.json_to_sheet(JSON.parse(jsonExcel));

      if (righe.length > 0) {
        var temp = [];
        Object.keys(righe[0]).forEach(key => {
          const max_width = righe.reduce((w, r) => Math.max(w, r[key].length), 20);
          temp.push({ wch: max_width });
        });
        ws["!cols"] = temp;
      }

      const wb = {Sheets: {'data':ws}, SheetNames: ['data']};
      const excelBuffer = XLSX.write(wb, {bookType:'xlsx', type: 'array'});
      const data= new Blob ([excelBuffer],{type:fileTypeExcel});
      fileSaver.saveAs(data, 'Progetti.xlsx');
    })
    .catch(error => {
      console.error(error);
      alert("Errore imprevisto durante il processo di export");
    }); 
  };

  const filtra = (body) => {
    axios.post(configData.API_BASE+'/api/project/table', body)
      .then(response => {
        //console.log(response.data)
        setListProject(response.data);
        setListProjectExport({
          nomeProject: filterProject.nomeProject,
          anno: filterProject.anno,
          attivo: filterProject.attivo,
          cup : filterProject.cup,
          codiceProject : filterProject.codiceProject,
          referenteProject : filterProject.referenteProject,
          page: -1,
          maxElementPage: filterProject.maxElementPage,
          orderColumn: orderBy,
          tipoOrder: orderDirection,
        })

        var temp = [];
        for (let i = 0; i < response.data.listProject.length; i++) {
            if (response.data.listProject[i].attivo == true){
                temp.push(createData(response.data.listProject[i].idProject,response.data.listProject[i].nomeProject, response.data.listProject[i].anno, "ATTIVO", response.data.listProject[i].cup, response.data.listProject[i].codiceProject, response.data.listProject[i].referenteProject));
            }else{
                temp.push(createData(response.data.listProject[i].idProject,response.data.listProject[i].nomeProject, response.data.listProject[i].anno, "NON ATTIVO", response.data.listProject[i].cup, response.data.listProject[i].codiceProject, response.data.listProject[i].referenteProject));
            }
            
        }
        setRows(temp);
      })
      .catch(error => {
        console.error(error);
    });
  }

  let {idSubProjectPassato} = useParams();

  useEffect(() => {
    var token=localStorage.getItem('token');
  
    if(token){
      axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    }else{
      axios.defaults.headers.common['Authorization']=null;
    }

    if (idSubProjectPassato != null){
      setFilterProject({
        ...filterProject,
        idProject : idSubProjectPassato
      })
    }
    filtra({
      idProject : idSubProjectPassato,
      nomeProject: filterProject.nome,
      anno: filterProject.anno,
      attivo: filterProject.attivo,
      cup: filterProject.cup,
      codiceProject : filterProject.codiceProject,
      referenteProject: filterProject.referenteProject,
      page: filterProject.page + 1,
      maxElementPage: filterProject.maxElementPage,
      orderColumn: orderBy,
      tipoOrder: orderDirection,

  })
  }, []);

  const buttonStyle = {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      alignSelf: 'flex-end',
    },
  };

  const handleEdit = (id) => {
    console.log(id)
    axios.get(configData.API_BASE+'/api/project/'+id)
    .then(response => {
      //console.log(response.data)
        setFormProject({
            idProject : response.data.idProject,
            nomeProject : response.data.nomeProject,
            anno : response.data.anno,
            attivo : response.data.attivo,
            cup: response.data.cup,
            codiceProject : response.data.codiceProject,
            referenteProject: response.data.referenteProject,
        })
    })
    .catch(error => {
      //console.error(error);
      alert(error.response.data);
    });
    handleOpenDialog(true);
  };


  const navigate = useNavigate();

  const handlePage = (idProjectPassato) => {
    navigate("/subProject/"+idProjectPassato);
  };


  const switchActive = (id) => {
    //console.log("sono dentor"+id)
    axios.post(configData.API_BASE+'/api/project/switch/'+id)
    .then(response => {
        applicaFiltri();
    })
    .catch(error => {
      //console.error(error);
      alert("Errore imprevisto durante il processo di switch stato del progetto");
    });
  }

	const handleDeleteConfirmation = (index) => {
    setDeleteRowIndex(index);
  }

	const handleDelete = (id) => {
    handleDeleteConfirmation(id);
  };

  const applicaFiltri = () => {
    setFilterProject({
      ...filterProject,
      page:0
    });
    filtra({
      idProject : filterProject.idProject,
      nomeProject: filterProject.nomeProject,
      anno: filterProject.anno,
      attivo: filterProject.attivo,
      cup: filterProject.cup,
      codiceProject : filterProject.codiceProject,
      referenteProject: filterProject.referenteProject,
      page: 1,
      maxElementPage: filterProject.maxElementPage,
      orderColumn: orderBy,
      tipoOrder: orderDirection,
    });
    //console.log(filterProject);

    setListProjectExport({
      nomeProject: filterProject.nomeProject,
      anno: filterProject.anno,
      attivo: filterProject.attivo,
      cup: filterProject.cup,
      codiceProject : filterProject.codiceProject,
      referenteProject: filterProject.referenteProject,
      page: -1,
      maxElementPage: filterProject.maxElementPage,
      orderColumn: orderBy,
      tipoOrder: orderDirection,
    })
  }

  const sort = (columnId, direction) => {
    setOrderBy(columnId);
    setOrderDirection(direction);
    setFilterProject({...filterProject, page:0})

    
    filtra({...filterProject,
      page: 1,
      orderColumn : columnId,
      tipoOrder : direction});
  }

  return (
    <Box>
      <Box mb={4} />
      <Box ml={2} mr={2}>
      <div>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <Accordion sx={{width : "100%"}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <strong>Filtri di ricerca </strong>
                    <SearchIcon icon = "search"/>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={3}>
                      <TextField label="CUP" name="cup" variant="standard" value={filterProject.cup} onChange={handleChange}  sx={{ minWidth: '78%'}}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField label="Nome Progetto" name="nomeProject" variant="standard" value={filterProject.nomeProject} onChange={handleChange}  sx={{ minWidth: '78%'}}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField label="Anno" name = "anno" variant="standard" value={filterProject.anno} onChange={handleChange} sx={{ minWidth: '78%'}}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl variant = "standard" sx={{ m: 0,  minWidth: '78%'}}>
                            <InputLabel>Stato Progetto</InputLabel>
                            <Select
                            name="attivo"
                            value={filterProject.attivo}
                            onChange={handleChange}
                            >
                            <MenuItem value="">Tutti</MenuItem>
                            <MenuItem value={true}>Attivo</MenuItem>
                            <MenuItem value={false}>Non Attivo</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField label="Codice Progetto" name="codiceProject" variant="standard" value={filterProject.codiceProject} onChange={handleChange}  sx={{ minWidth: '78%'}}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField label="Referente Progetto" name="referenteProject" variant="standard" value={filterProject.referenteProject} onChange={handleChange}  sx={{ minWidth: '78%'}}/>
                    </Grid>
                    <Grid item xs={12}>
                      <br></br>
                      <Button variant="contained" color="primary" onClick={applicaFiltri}>
                        Filtra
                      </Button>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
          </Box>
        </div>

        <Box mb={4}/>
        <Paper sx={{ width: '100%' }}>
          <TableContainer  style={{maxHeight: "60vh"}}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead style={{position:"sticky", top:0, zIndex:99, backgroundColor: "white", width: "100%"}}>
                <TableRow>
                  <TableCell align="left" colSpan={100}>
                    <EnhancedTableToolbar rowCount={rows.length} onSelectAllClick={() => handleSelectAllClick(rows, rows.length, selected.length)} handleChange={handleChange} numSelected={selected.length} selezionati={selected}></EnhancedTableToolbar> 
                  </TableCell>
                  <TableCell align="right">
                  </TableCell>
                </TableRow>
                <TableRow>
                {columns.map((column) => (
                  <TableCell
                    sx={{
                        cursor: column.id !== 'action' ? 'pointer' : 'default',
                        position: 'sticky',
                        left: column.id === 'cup' ? 0 : 'auto',
                        zIndex: column.id === 'cup' ? 1 : 'auto',
                    }}
                    key={column.id}
                    align={column.align}
                    style={{
                      top: 57,
                      minWidth: column.minWidth,
                    }}
                    onClick={() => {
                      if (column.id !== 'actions') {
                        sort(column.id, orderDirection === 'asc' ? 'desc' : 'asc');
                      }
                    }}
                  >
                    <strong>{column.label}</strong>
                    {column.id !== 'actions' ? (
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? orderDirection : 'asc'}
                      />
                    ) : null}
                  </TableCell>
                ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.idProject);
                    return (
                    <>
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.id} aria-checked={isItemSelected} selected={isItemSelected}>
                        {columns.map((column) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          const value = row[column.id];
                          if (column.id === 'cup') { // Condizione per la colonna 'nome'
                            return (
                              <TableCell 
                                key={column.id} 
                                align={column.align}
                                sx={{
                                    cursor: 'default', // Imposta il cursore sulla colonna 'nome'
                                    position: 'sticky',
                                    //overflow: 'hidden',
                                    //textOverflow: 'ellipsis',
                                    //whiteSpace: 'nowrap',
                                    left: 0,
                                    zIndex: 1,
                                    backgroundColor : 'white'
                                  }}
                              >
                                {value}
                              </TableCell>
                            );
                          }
                          if (column.id === 'actions') {
                            return (
                                <TableCell key={column.id} style={{width: 230}}>
                                    <div style={{ display: 'flex'}}>
                                        <Checkbox
                                        color="primary"
                                        checked={isItemSelected}
                                        onClick={(event) => handleClick(event, row.idProject)}
                                        inputProps={{
                                            'aria-labelledby': labelId,
                                        }}
                                        />
                                        <Button onClick={() => handleEdit(row.idProject)} size="small" style={{marginLeft : '0px'}}>
                                            <EditIcon icon="edit" color="blue" />
                                        </Button>
                                        <Button onClick={() => handleDelete(index)} size="small" style={{marginLeft : '0px'}}>
                                            <DeleteIcon color="error"/>
                                        </Button>
                                        <Button size="small" style={{marginLeft : '0px'}} title={row.attivo == "ATTIVO" ? "Disattiva Progetto" : "Attiva Progetto"}>
                                            {row.attivo == "ATTIVO" ? 
                                                <RemoveCircleIcon
                                                onClick={() => switchActive(row.idProject)} 
                                                color="error"/> : 
                                                <CheckIcon
                                                onClick={() => switchActive(row.idProject)} 
                                                color="success"/>
                                            }
                                        </Button>
                                        <Button onClick={() => handlePage(row.idProject)} size="small" style={{marginLeft : '0px'}}><ManageSearchIcon color="action"/></Button>
                                    </div>
                                </TableCell>
                            );
                          }
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number' ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 50]}
            component="div"
            count={listProject.countProject}
            //rowsPerPage={rowsPerPage}
            //page={page}
            rowsPerPage={filterProject.maxElementPage}
            page={filterProject.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        {(formProject.idProject == null || formProject.idProject == '') ? <DialogTitle>Aggiungi un Progetto</DialogTitle> : <DialogTitle>Modifica un Progetto</DialogTitle>}
        <DialogContent>
            <FormProject formProject={formProject} setFormProject = {setFormProject} error = {error} setError = {setError} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annulla</Button>
          <Button onClick={addAndCloseDialog} color="primary"> Salva </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteRowIndex !== null} onClose={handleDeleteCancel}>
        <DialogTitle>Elimina riga</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sei sicuro di voler eliminare questa riga?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Annulla
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Conferma
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={deleteRowIndexMultiple !== null} onClose={handleDeleteCancelMultiple}>
        <DialogTitle>Elimina le righe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sei sicuro di voler eliminare le righe selezionate?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancelMultiple} color="primary">
            Annulla
          </Button>
          <Button onClick={handleDeleteSelected} color="primary">
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
