import 'bootstrap/dist/css/bootstrap.min.css';
//import './TestForm.css';
import './Domanda.css';
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import axios from "axios";
import * as React from "react";
import { useEffect } from "react";
import { configData } from "./configData/configData";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import {CircularProgress} from "@mui/material";
import {Backdrop} from "@mui/material";

//let FormWithNav = applyNav(Form);

axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
axios.defaults.headers.common['Content-Type'] = localStorage.getItem('id');




let dateNow = new Date().toISOString().slice(0,10);
let sediciAnniFa = (dateNow.slice(0,4)-16) + dateNow.slice(4,10);
const MAX_FILE_SIZE = 512


function transformErrors(errors) {
    return errors.map(error => {
        if (error.message === "is a required property") {
            error.message = "Campo necessario";
        } else if (error.message === "should NOT have fewer than 1 items") {
            error.message = "Scegliere ALMENO 1 opzione";
        } else if (error.message === "should be equal to constant") {
            error.message = "Devi accettare per proseguire";
        } else if (error.message.includes("should NOT be longer than")) {
            let numero = error.message.match(/\d+/);
            error.message = "Non deve essere più lungo di " + numero + " caratteri";
        } else if (error.message.includes("should NOT be shorter than")) {
            let numero = error.message.match(/\d+/);
            error.message = "Non deve essere più corto di " + numero + " caratteri";
        }

        return error;
    });
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function FormDomanda(props) {

    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        document.location.href = configData.SITE_URL+"/listaForm";
    };

    let onSubmit = ({ formData }) => {
        var token = localStorage.getItem('token');
        if (token) {
            setLoading(true);
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
            axios.get(configData.API_BASE + "/api/forms/submit/risposta/" + props.formId + "/" + localStorage.getItem('id') + "/exist").then(
                esiste => {
                    if (esiste.data) {
                        alert("C'è già una risposta presente nel sistema");
                        document.location.href = configData.SITE_URL+"/listaForm";
                    } else {
                        axios.post(configData.API_BASE + "/api/forms/submit/", {
                            user_id: localStorage.getItem('id'),
                            form_id: props.formId,
                            submit: JSON.stringify(formData)
                        }).then(res => {
                            axios.post(configData.API_BASE+"/api/forms/submit/dataform/"+res.data.id, {
                            }).then(azione => {
                                    setLoading(false);
                                    setOpen(true);

                                }
                            )
                        });
                    }
                })
        } else {
            axios.defaults.headers.common['Authorization'] = null;
        }





    };

    const style = {
        //backgroundColor:"whitesmoke",
    }

    function ErrorListTemplate(errors) {
        return (
            <div>
                <h2>Custom error list</h2>
                <ul>
                    {errors.map(error => (
                        <li key={error.stack}>
                            {error.stack}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }



    const customValidate = (formData, errors) => {

        if (formData.anagrafica?.dataNascita) {
            if (formData.anagrafica.dataNascita >= sediciAnniFa) {
                errors.anagrafica?.dataNascita.addError("Devi aver compiuto almeno il 16° anno di età");
            }
        }
        if (formData.anagrafica?.dataEmissioneDocumentoEspatrio) {
            if (formData.anagrafica.dataEmissioneDocumentoEspatrio >= dateNow) {
                errors.anagrafica?.dataEmissioneDocumentoEspatrio.addError("Il documento deve avere una data di emissione valida");
            }
        }
        if (formData.anagrafica?.dataScadenzaDocumentoEspatrio) {
            if (formData.anagrafica.dataScadenzaDocumentoEspatrio <= dateNow) {
                errors.anagrafica?.dataScadenzaDocumentoEspatrio.addError("Non puoi registrare un documento scaduto");
            }
        }


        return errors;
    }

    return (
        <div>
            <div>
            <Snackbar open={open} onClose={handleClose}  autoHideDuration={6000} anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Form inviato correttamente
                </Alert>
            </Snackbar>
        </div>
        <div>
        <Stack>
            <Form
                schema={props.schema}
                uiSchema={props.uiSchema}
                formData={props.formData}
                validator={validator}
                customValidate={customValidate}
                onChange={(e) => {
                    const { formData } = e
                    //console.log(e)
                    props.aggiornaStato(formData);
                }}
                onSubmit={onSubmit}
                transformErrors={transformErrors}
                showErrorList={false}
                liveValidate
                omitExtraData
                liveOmit={true}
            />
            <Backdrop sx={{color:'#ffffff', zIndex:999}} open={loading} ><CircularProgress color="inherit"/></Backdrop>
        </Stack>
        </div>

        </div>


    );
}

export default FormDomanda;