import * as React from "react";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";

function ScegliBottoneListaForm(props) {

    function Visualizza(props) {
        return <div><Link component={Link} to={`/formRisposta/${props.formId}`}><Button className='compilaButton' size="small">Visualizza</Button></Link></div>;
    }

    function Compila(props) {
        return <div><Link component={Link} to={`/form/${props.formId}`}><Button className='compilaButton' size="small">Compila</Button></Link></div>;
    }

    function ScegliBottone(props) {
        const scelta = props.isTrue;
        return (
            <>
                { scelta ? <Visualizza formId={props.formId} /> : <Compila formId={props.formId}/> }
            </>
        );
    }

    return(
        <ScegliBottone formId={props.formId} isTrue={props.isTrue}></ScegliBottone>
    )


}

export default ScegliBottoneListaForm;