import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import TestForm from "./TestForm";
import reportWebVitals from './reportWebVitals';
import Domanda from "./Domanda";
import App from "./App"




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <div>
        <App/>
    </div>

);

//<Domanda/>


// If you want to start measuring performance in your app, pass a function
//  to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
