import React, { Component } from "react";
import * as PropTypes from "prop-types";
import FormDomanda from "./FormDomanda";
import AllegatiScaricabiliForm from "./AllegatiScaricabiliForm";
import Card from "@mui/material/Card";
import './Domanda.css';
import CardContent from "@mui/material/CardContent";
import axios from "axios";
import {ButtonGroup, CardHeader, CardMedia} from "@mui/material";
import mainLogo from "./image/logoErasmus.PNG";
import ueDisclaimer from "./image/UE_disclaimer.PNG";
import Button from "@mui/material/Button";
import {configData} from "./configData/configData";
import {useParams} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import {Backdrop} from "@mui/material";



axios.defaults.headers.common['Authorization']=localStorage.getItem('token');

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

FormDomanda.propTypes = {uiSchema: PropTypes.any};


class Domanda extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: {},
            uiSchema: {},
            formData:{},
            currentPage: 1,
            pages: 1,
            cssCustom: "",
            errors: 0,
            allegati: [],
            loading:true,
        };
    }

    componentDidMount() {

        var token=localStorage.getItem('token');

        if(token){
            axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
            axios.get(configData.API_BASE+"/api/forms/"+this.props.params.formId).then(
                res=>{
                    let jsonSchema=JSON.parse(res.data.jsonschema);
                    let uiSchema=JSON.parse(res.data.uischema);



                    let dateNow = new Date().toISOString().slice(0,10);

                    if(jsonSchema.properties.dataAttuale!=undefined){
                        jsonSchema.properties.dataAttuale.default=dateNow;
                    }


                    let css = "";
                    for(let i=1; i<=res.data.pages; i++) {
                        if (i != this.state.currentPage) {
                            css+= ".f" + i + " {\n" +
                                "    display: none;\n" +
                                "}\n" +
                                ".MuiGrid-item:has(.f" + i +") {\n" +
                                "   display: none;" +
                                "}\n"
                        }
                    }
                    if (this.state.currentPage!=res.data.pages) {
                        css+=".submitButton {\n" +
                            "    visibility: hidden;\n" +
                            "}"
                    }
                    this.setState({...this.state,schema:jsonSchema,uiSchema:uiSchema,pages:res.data.pages, cssCustom: css});
                    axios.get(configData.API_BASE+"/api/formsAttach/"+this.props.params.formId).then(
                        risposta=>{
                            let allegatiTemp=this.state.allegati;
                            risposta.data.map((allegato)=>
                                allegatiTemp.push(allegato)
                            )
                            this.setState({...this.state, allegati:allegatiTemp, loading:false});
                        }
                    )
                }
            )





            //console.log(this.state.allegati);
        }else{
            axios.defaults.headers.common['Authorization']=null;
        }
    }

    setErrors(errors) {
        //console.log(errors);
    }

    handleCallback = (newFormData) =>{
        this.setState({...this.state,formData: newFormData})
    }

    nextPage() {

        let current = this.state.currentPage;
        let css = "";
        //let controlloTotale=document.querySelectorAll(".Mui-error");
        let controlloPagina=document.querySelectorAll(".f"+current+" .Mui-error");
        /*if(this.state.pages==current+1 && this.state.lastPage==false){
           this.setState({...this.state, lastPage: true});
        }*/
        if(/*(*/controlloPagina.length==0/*&&controlloTotale.length!=0)||(controlloPagina.length==0&&controlloTotale.length==0&&this.state.lastPage==true)*/) {
            current++;
            for (let i = 1; i <= this.state.pages; i++) {
                if (i != current) {
                    css += ".f" + i + " {\n" +
                        "    display: none;\n" +
                        "}\n" +
                        ".MuiGrid-item:has(.f" + i + ") {\n" +
                        "   display: none;" +
                        "}\n"
                }
            }
            if (current != this.state.pages) {
                css += ".submitButton {\n" +
                    "    visibility: hidden;\n" +
                    "}"
            }
            this.setState({...this.state,cssCustom: css, currentPage: current});
            window.scrollTo(0, 0);
        }else{
            alert("Compila i campi in rosso");
        }

    }
    previousPage() {

        let current = this.state.currentPage;
        current--;
        let css = ""
        for(let i=1; i<=this.state.pages; i++) {
            if (i != current) {
                css+= ".f" + i + " {\n" +
                    "    display: none;\n" +
                    "}\n" +
                    ".MuiGrid-item:has(.f" + i +") {\n" +
                    "   display: none;" +
                    "}\n"
            }
        }
        if (current!=this.state.pages) {
            css+=".submitButton {\n" +
                "    visibility: hidden;\n" +
                "}"
        }
        this.setState({...this.state, cssCustom: css, currentPage: current});
        window.scrollTo(0,0);

    }



render() {

        return (
            <Card
                style={{
                    position: 'absolute', left: '50%',
                    transform: 'translate(-50%)',
                    boxShadow: "1px 3px 5px grey",
                    borderRadius: "6px",
                    padding: '1em',
                }}>
                <CardMedia component="img" image={mainLogo} alt="logo"/>
                <CardContent>
                    <style id="cssPagine">
                        {this.state.cssCustom}
                    </style>
                    <ButtonGroup>
                        <Button variant="contained" className='backButton' style={{display: this.state.currentPage == 1 ? "none" : "block"}} onClick={()=>{this.previousPage()}}>previous page</Button>
                        <Button variant="contained" className='nextButton' style={{display: this.state.currentPage == this.state.pages ? "none" : "block"}} onClick={()=>{this.nextPage()}}>next page</Button>
                    </ButtonGroup>
                    <FormDomanda setErrors={this.setErrors} schema={this.state.schema} uiSchema={this.state.uiSchema} formData={this.state.formData} formId={this.props.params.formId} aggiornaStato={this.handleCallback}/>
                    {
                        document.querySelectorAll("input[type=file]").forEach(element => {
                            element.addEventListener("change",function(){
                                if(this.files[0].size>524288){
                                    alert("Il limite massimo di upload è 512 KB per file");
                                    this.value = "";
                                }
                                if(this.files[0].type.search("pdf") == -1) {
                                    alert("Il formato dell'allegato caricato deve essere .pdf");
                                    this.value = "";
                                }
                            })
                        })
                    }
                    {this.state.currentPage==1 &&
                        <div><h4>Dichiaro</h4>
                            <ul>
                                <li>Di aver preso visione del bando e accettarne il contenuto</li>
                                <li>Di essere in possesso dei requisiti formali richiesti dal Bando per l'ammissione alla selezione</li>
                                <li>Di non aver riportato condanne penali e di non avere precedenti penali pendenti (in caso contrario, segnalare quali)</li>
                                <li>Che i dati riportati nel form sono veritieri</li>
                                <li>Non usufruire di altri finanziamenti per soggiorni all'estero, erogati su fondi dell'Unione Europea</li>
                            </ul>

                        </div>
                    }

                    {this.state.allegati.map((allegato)=>{
                        if(allegato.atPage==this.state.currentPage){
                            return(
                                <AllegatiScaricabiliForm nome={allegato.attach.fileName} path={allegato.attach.filePath} descrizione={allegato.descrizione}/>
                            );
                        }
                    })}


                    <ButtonGroup>
                        <Button variant="contained" className='backButton' style={{display: this.state.currentPage == 1 ? "none" : "block"}} onClick={()=>{this.previousPage()}}>previous page</Button>
                        <Button variant="contained" className='nextButton' style={{display: this.state.currentPage == this.state.pages ? "none" : "block"}} onClick={()=>{this.nextPage()}}>next page</Button>
                    </ButtonGroup>
                </CardContent>
                <CardMedia component="img" image={ueDisclaimer} alt="logo"/>
                <Backdrop sx={{color:'#ffffff', zIndex:999}} open={this.state.loading} ><CircularProgress color="inherit"/></Backdrop>
            </Card>

        );
    }
}

export default withParams(Domanda);