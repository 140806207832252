import { configData } from './configData/configData';
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, AppBar, Toolbar, Grid, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, useTheme, DialogContentText, TableSortLabel } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import FormSubProject from './FormSubProject';
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import { formatMs } from '@material-ui/core';
import DownloadIcon from '@mui/icons-material/Download';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import {styled, alpha } from '@mui/material/styles';
import CustomizedMenus from './download';
import {useLocation, useParams} from "react-router-dom";
import XLSX from "sheetjs-style";
import * as fileSaver from "file-saver";
import moment from 'moment';
import {Routes, Route, useNavigate} from 'react-router-dom';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';


const columns = [
  { id: 'actions'},
  { id: 'idProgAppartenenza', sort: 'idProgAppartenenza', label: 'Nome Progetto App.', minWidth: 200 },
  { id: 'idSubProject', sort: 'idSubProject',label: 'ID', minWidth: 100 },
  { id: 'cittaDest', sort: 'cittaDest',label: 'Citta Destinazione', minWidth: 200 },
  { id: 'inizioMobilita', sort: 'inizioMobilita', label: 'Data Inizio Mobilità', minWidth: 200 },
  { id: 'fineMobilita',  sort: 'fineMobilita', label: 'Data Fine Mobilità', minWidth: 200 },
  { id: 'templLE', sort: 'templLE', label: 'Template L.A.', minWidth: 200},
  { id: 'templContratto', sort: 'templContratto', label: 'Template Contratto', minWidth: 200},
  { id: 'idHostingPartner', sort: 'idHostingPartner', label: 'Hosting Partner', minWidth: 240},
  { id: 'idSender', sort: 'idSender', label: 'Sender', minWidth: 200},
];




function createData(idProgAppartenenza, idSubProject, cittaDest, inizioMobilita, fineMobilita, templLE, templContratto, idHostingPartner, idSender, page, maxElementPage) {
  return {
    idProgAppartenenza, idSubProject, cittaDest, inizioMobilita, fineMobilita, templLE, templContratto, idHostingPartner, idSender, page, maxElementPage,
  };
}


export default function ColumnGroupingTable() {
  //const [seleceterId, setSelecterId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteRowIndex, setDeleteRowIndex] = useState(null);
  const [deleteRowIndexMultiple, setDeleteRowIndexMultiple] = useState(null);
  const [rows, setRows] = useState([]);
  const [orderBy, setOrderBy] = useState("idSubProject");
  const [orderDirection, setOrderDirection] = useState("desc");
  const [selected, setSelected] = React.useState([]);
  const [cittaData, setCittaData] = useState([]);
  const [formData, setFormData] = useState({
    idSubProject: null,
    idProgAppartenenza: '',
    cittaDest: '',
    inizioMobilita: '',
    fineMobilita: '',
    templLE: '',
    templContratto: '',
    idHostingPartner: '',
    idSender: '',
  });

  // const [error2, setError2] = useState({
  //   inizioMagFine : false,
  // });

  const [error, setError] = useState({
    cittaDest: false,
    inizioMobilita: false,
    fineMobilita: false,
    idProgAppartenenza: false,
    idHostingPartner: false,
    idSender: false,
  });

  const theme = useTheme();

  const navigate = useNavigate();

  const handlePage = (idSubProgettoPass) => {
    console.log(idSubProgettoPass);

    getIdProgApp()

    navigate("/Project/"+idSubProgettoPass);
  };

  const handleChangePage = (event, newPage) => {
    setFilterSubProject({
      ...filterSubProject,
      page: newPage,
    });
    filtra({
        idProgAppartenenza: filterSubProject.idProgAppartenenza,
        cittaDest: filterSubProject.cittaDest,
        inizioMobilita: filterSubProject.inizioMobilita,
        fineMobilita: filterSubProject.fineMobilita,
        templLE: filterSubProject.templLE,
        templContratto: filterSubProject.templContratto,
        idHostingPartner: filterSubProject.idHostingPartner,
        idSender: filterSubProject.idSender,
        page: newPage+1,
        maxElementPage: filterSubProject.maxElementPage,
        orderColumn: orderBy,
        tipoOrder: orderDirection,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilterSubProject({
      ...filterSubProject,
      maxElementPage: event.target.value,
      page:0
    });
    filtra({
        idProgAppartenenza: filterSubProject.idProgAppartenenza,
        cittaDest: filterSubProject.cittaDest,
        inizioMobilita: filterSubProject.inizioMobilita,
        fineMobilita: filterSubProject.fineMobilita,
        templLE: filterSubProject.templLE,
        templContratto: filterSubProject.templContratto,
        idHostingPartner: filterSubProject.idHostingPartner,
        idSender: filterSubProject.idSender,
        page: 1,
        maxElementPage: event.target.value,
        orderColumn: orderBy,
        tipoOrder: orderDirection,
    });
  };


//inizio checkbox
const EnhancedTableToolbar = (props) => {
  const { numSelected,selezionati, state, onSelectAllClick, rowCount} = props;

  return (  
    <Toolbar
      sx={{
        pl: { sm: 0 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      <Checkbox
        color="primary"
        indeterminate={numSelected > 0 && numSelected < rowCount}
        checked={rowCount > 0 && numSelected === rowCount}
        onChange={onSelectAllClick}
        inputProps={{
          'aria-label': 'select all desserts',
        }}
        sx = {{
          position: 'sticky',
          //overflow: 'hidden',
          //textOverflow: 'ellipsis',
          //whiteSpace: 'nowrap',
          left: 0,
          zIndex: 1,
        }}
      />
      {numSelected > 0 ? (
        <Typography 
          color="inherit"
          variant="subtitle1"
          component="div"
          sx = {{
            position: 'sticky',
            //overflow: 'hidden',
            //textOverflow: 'ellipsis',
            //whiteSpace: 'nowrap',
            left: 50,
            zIndex: 1,
          }}
        >
          {numSelected} selezionato/i
          <Button variant="contained" onClick={exportSelected} sx={{left: 20}}><DownloadIcon></DownloadIcon>Download Selezionati</Button>
          <Button variant="contained" onClick={handleDeleteMultiple} color="error" sx={{left: 50}}>Elimina Selezionati
          <DeleteIcon/>
          </Button>
        </Typography>
      ) : (
        <Typography
          variant="h6"
          id="tableTitle"
          component="div"
          sx = {{
          position: 'sticky',
          //overflow: 'hidden',
          //textOverflow: 'ellipsis',
          //whiteSpace: 'nowrap',
          display : 'flex',
          left: 50,
          zIndex: 1,
        }}
        >
        <Button variant="outlined" onClick={handleNew} sx={{left: 10}}> 
          <AddIcon color="success"/>
          <strong>Nuovo Sotto-Progetto</strong>
        </Button>
        <Button variant="contained" onClick={() => exportTable(listSubProjectExport)} sx={{left: 30}}><DownloadIcon></DownloadIcon>Download</Button>
        
        </Typography>
      )}
    </Toolbar>
  
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const isSelected = (id) => selected.indexOf(id) !== -1;

const handleClick = (event, id) => {
  //console.log("sono qui");
  //console.log(id);
  const selectedIndex = selected.indexOf(id);
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected,id);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1),
    );
  }
  //console.log("Sono selezionati alcuni" + newSelected);
  setSelected(newSelected);
  
};


const handleSelectAllClick = (event, rowCount, selectedCount) => {
  if (event && (selectedCount != rowCount)) {
    const newSelected = rows.map((n) => n.idSubProject);
    //console.log("sono selezionati " + newSelected);
    setSelected(newSelected);
    return;
  }
  setSelected([]);
  //console.log([]);
};

const handleDeleteMultiple = (selected) => {
  handleDeleteConfirmationMultiple(selected);
};

const handleDeleteConfirmationMultiple = (selected) => {
  setDeleteRowIndexMultiple(selected);
}

const handleDeleteCancelMultiple = () => {
  setDeleteRowIndexMultiple(null);
};

const handleDeleteSelected = () => {
  //console.log(selected)
    axios.delete(configData.API_BASE+'/api/subProject/delete', {data : selected})
      .then(response => {
        //console.log(response.data);
        setSelected([]);
        applicaFiltri();
        setDeleteRowIndexMultiple(null);
      })
      .catch(error => {
        //console.error(error);
        alert("Errore imprevisto durante il processo di delete delle righe selezionate");
    });
};
//fine checkbox


  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDeleteCancel = () => {
    setDeleteRowIndex(null);
  };

	const handleNew = () =>{
		//setSelecterId(null);
    resetForm()
    setError({
        cittaDest: false,
        inizioMobilita: false,
        fineMobilita: false,
        idProgAppartenenza: false,
        idHostingPartner: false,
        idSender: false,
    });
		setOpenDialog(true);
	}

  const handleDeleteConfirm = () => {
    if (deleteRowIndex !== null) {
      const id = rows[deleteRowIndex].idSubProject
      axios.delete(configData.API_BASE+'/api/subProject/'+id)
        .then(response => {
          //console.log("ho eliminato il partner");
          filtra({
            idProgAppartenenza: filterSubProject.idProgAppartenenza,
            cittaDest: filterSubProject.cittaDest,
            inizioMobilita: filterSubProject.inizioMobilita,
            fineMobilita: filterSubProject.fineMobilita,
            templLE: filterSubProject.templLE,
            templContratto: filterSubProject.templContratto,
            idHostingPartner: filterSubProject.idHostingPartner,
            idSender: filterSubProject.idSender,
            page: filterSubProject.page + 1,
            maxElementPage: filterSubProject.maxElementPage,
            orderColumn: orderBy,
            tipoOrder: orderDirection,
        });
        setDeleteRowIndex(null);
        })
        .catch(error => {
          //console.error(error);
          alert("Errore imprevisto durante il processo di delete");
      });
      //console.log(rows[deleteRowIndex].idPartner);
    }
  };


  const addAndCloseDialog = (body) => {
    if (formData.idSubProject == '' || formData.idSubProject == null) {
        console.log(formData)
      if(
            formData.cittaDest == "" || formData.cittaDest == null ||
            formData.inizioMobilita == "" || formData.inizioMobilita == null ||
            formData.fineMobilita == "" || formData.fineMobilita == null ||
            formData.idProgAppartenenza == "" || formData.idProgAppartenenza == null ||
            formData.idHostingPartner == "" || formData.idHostingPartner == null ||
            formData.idSender == "" || formData.idSender == null
      ){
        setError({
            cittaDest: formData.cittaDest == "" || formData.cittaDest == null,
            inizioMobilita:  formData.inizioMobilita == "" || formData.inizioMobilita == null,
            fineMobilita: formData.fineMobilita == "" || formData.fineMobilita == null,
            idProgAppartenenza:   formData.idProgAppartenenza == "" || formData.idProgAppartenenza == null,
            idHostingPartner: formData.idHostingPartner == "" || formData.idHostingPartner == null,
            idSender:  formData.idSender == "" || formData.idSender == null,
        })
      }else if(formData.inizioMobilita > formData.fineMobilita){
        setError({
            inizioMobilita:  true,
            fineMobilita: true,
        })
      }else{
        axios.post(configData.API_BASE+'/api/subProject', formData)
        .then(response => {
          console.log(response.data);
          applicaFiltri()
        })
        .catch(error => {
          if (error.response.status == 400 && error.response.data.id == null){
            alert("Sotto-Progetto già esistente");
          }
        });

        setError({
            cittaDest: false,
            inizioMobilita: false,
            fineMobilita: false,
            idProgAppartenenza: false,
            idHostingPartner: false,
            idSender: false,
        });
        setOpenDialog(false);
      }
    }
    else{
      if(
            formData.cittaDest == "" || formData.cittaDest == null ||
            formData.inizioMobilita == "" || formData.inizioMobilita == null ||
            formData.fineMobilita == "" || formData.fineMobilita == null ||
            formData.idProgAppartenenza == "" || formData.idProgAppartenenza == null ||
            formData.idHostingPartner == "" || formData.idHostingPartner == null ||
            formData.idSender == "" || formData.idSender == null
      ){
        setError({
            cittaDest: formData.cittaDest == "" || formData.cittaDest == null,
            inizioMobilita:  formData.inizioMobilita == "" || formData.inizioMobilita == null,
            fineMobilita: formData.fineMobilita == "" || formData.fineMobilita == null,
            idProgAppartenenza:   formData.idProgAppartenenza == "" || formData.idProgAppartenenza == null,
            idHostingPartner: formData.idHostingPartner == "" || formData.idHostingPartner == null,
            idSender:  formData.idSender == "" || formData.idSender == null,
        })
      }else{
        axios.put(configData.API_BASE+'/api/subProject/'+formData.idSubProject, formData)
        .then(response => {
          //console.log(response.data);
          applicaFiltri()
        })
        .catch(error => {
          alert(error.response.data);
          console.error(error);
        });

        setError({
            cittaDest: false,
            inizioMobilita: false,
            fineMobilita: false,
            idProgAppartenenza: false,
            idHostingPartner: false,
            idSender: false
        });
        setOpenDialog(false);
      }
    }
  };

  //filtri state
  const [filterSubProject, setFilterSubProject] = useState({
        idProgAppartenenza: null,
        cittaDest: null,
        inizioMobilita: null,
        fineMobilita: null,
        templLE: null,
        templContratto: null,
        idHostingPartner: null,
        idSender: null,
        page: 0,
        maxElementPage: 10,
  });

  //state partner
  const [listSubProject, setListSubProject] = useState({
    records: [],
    totalElement: 0
  });

  const getIdProgApp = (subprojId) => {
    for(let i = 0; i < listSubProject.records.length; i++) {
      if (listSubProject.records[i].idSubProject == subprojId) {
        return listSubProject.records[i].idProgAppartenenza.idProject
      }
    }
  }

  const handleChange = (event) => {
    setFilterSubProject({
      ...filterSubProject,
      [event.target.name]: event.target.value,
    });
  };

  const resetForm = () => {
    setFormData({
        idSubProject: null,
        cittaDest: '',
        inizioMobilita: '',
        fineMobilita: '',
        templLE: '',
        templContratto: '',
        idProgAppartenenza: '',
        idHostingPartner: '',
        idSender: '',
    });
  };


  const exportSelected = (body) => {
    //console.log(...selected)
    axios.post(configData.API_BASE+'/api/subProject/download', selected)
      .then(response => {
        const righe = response.data.listSubProject.map(item => (
        {
            "ID Sotto-Progetto" : item.idSubProject ,
            "Nome Progetto App." : item.idProgAppartenenza.nomeProject,
            "Città Destinazione" : item.cittaDest.descrizione,
            "Data Inizio Mobilità" : moment(item.inizioMobilita).format('YYYY-MM-DD'),
            "Data Fine Mobilità" : moment(item.fineMobilita).format('YYYY-MM-DD'),
            "ID Progetto App." : item.idProgAppartenenza.idProject,
            "Nome Hosting Partner":  item.idHostingPartner.nome,
            "Nome Sender" : item.idSender.nome}))
        let jsonExcel= JSON.stringify(righe);
        console.log(JSON.parse(jsonExcel))
        console.log(response)
        //"Tipologia Partner" : item.user.roles[0].id == 4 ? "Scuola" : "Hosting Partner"
        const ws = XLSX.utils.json_to_sheet(JSON.parse(jsonExcel), {});

        if (righe.length > 0) {
          var temp = [];
          Object.keys(righe[0]).forEach(key => {
            const max_width = righe.reduce((w, r) => Math.max(w, r[key].length), 20);
            temp.push({ wch: max_width });
          });
          ws["!cols"] = temp;
        }

        const wb = {Sheets: {'data':ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, {bookType:'xlsx', type: 'array'});
        const data= new Blob ([excelBuffer],{type:fileTypeExcel});
        fileSaver.saveAs(data, 'SottoProgetti.xlsx');
      })
      .catch(error => {
        //console.error(error);
        alert("errore nel processo di export");
    });
  };


  const [listSubProjectExport, setListSubProjectExport] = useState({
    records: [],
    totalElement: 0
  });

  
  const fileTypeExcel = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const exportTable = (body) => {
    axios.post(configData.API_BASE+'/api/subProject/table', body)
    .then(response => {
      const righe = response.data.listSubProject.map(item => ({
        "ID Sotto-Progetto" : item.idSubProject ,
        "Nome Progetto App." : item.idProgAppartenenza.nomeProject,
        "Città Destinazione" : item.cittaDest.descrizione,
        "Data Inizio Mobilità" : moment(item.inizioMobilita).format('YYYY-MM-DD'),
        "Data Fine Mobilità" : moment(item.fineMobilita).format('YYYY-MM-DD'),
        "ID Progetto App." : item.idProgAppartenenza.idProject,
        "Nome Hosting Partner":  item.idHostingPartner.nome,
        "Nome Sender" : item.idSender.nome}))
      let jsonExcel= JSON.stringify(righe);
      const ws = XLSX.utils.json_to_sheet(JSON.parse(jsonExcel), {});

      if (righe.length > 0) {
        var temp = [];
        Object.keys(righe[0]).forEach(key => {
          const max_width = righe.reduce((w, r) => Math.max(w, r[key].length), 20);
          temp.push({ wch: max_width });
        });
        ws["!cols"] = temp;
      }

      const wb = {Sheets: {'data':ws}, SheetNames: ['data']};
      const excelBuffer = XLSX.write(wb, {bookType:'xlsx', type: 'array'});
      const data= new Blob ([excelBuffer],{type:fileTypeExcel});
      fileSaver.saveAs(data, 'SottoProgetti.xlsx');
    })
    .catch(error => {
      //console.error(error);
      alert("errore nel processo di export");
    });
  };

  const filtra = (body) => {
    axios.post(configData.API_BASE+'/api/subProject/table', body)
      .then(response => {
        setListSubProject(response.data);
        setListSubProjectExport({
            idProgAppartenenza: filterSubProject.idProgAppartenenza,
            cittaDest: filterSubProject.cittaDest,
            inizioMobilita: filterSubProject.inizioMobilita,
            fineMobilita: filterSubProject.fineMobilita,
            templLE: filterSubProject.templLE,
            templContratto: filterSubProject.templContratto,
            idHostingPartner: filterSubProject.idHostingPartner,
            idSender: filterSubProject.idSender,
            page: -1,
            maxElementPage: filterSubProject.maxElementPage,
            orderColumn: orderBy,
            tipoOrder: orderDirection,
        })

        var temp = [];
        for (let i = 0; i < response.data.listSubProject.length; i++) {
            const dateInizio = moment(response.data.listSubProject[i].inizioMobilita).format('YYYY-MM-DD');
            const dateFine = moment(response.data.listSubProject[i].fineMobilita).format('YYYY-MM-DD');

            temp.push(createData(response.data.listSubProject[i].idProgAppartenenza.nomeProject, response.data.listSubProject[i].idSubProject, 
                response.data.listSubProject[i].cittaDest.descrizione, dateInizio, dateFine, 
                response.data.listSubProject[i].templLE, response.data.listSubProject[i].templContratto, 
                response.data.listSubProject[i].idHostingPartner.nome, response.data.listSubProject[i].idSender.nome));
        }
        setRows(temp);
        console.log(temp);
      })
      .catch(error => {
        console.error(error);
    });
  }

  let {idProjectPassato} = useParams();

  useEffect(() => {
    var token=localStorage.getItem('token');
  
    if(token){
      axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    }else{
      axios.defaults.headers.common['Authorization']=null;
    }

    //getCitta();
    //console.log(getCitta());
    /*if (idProjectPassato != null){
      setFilterSubProject({
        ...filterSubProject,
        idProgAppartenenza : idProjectPassato
      })
    }
    */
    filtra({
      idProgAppartenenza: filterSubProject.idProgAppartenenza,
      cittaDest: filterSubProject.cittaDest,
      inizioMobilita: filterSubProject.inizioMobilita,
      fineMobilita: filterSubProject.fineMobilita,
      templLE: filterSubProject.templLE,
      templContratto: filterSubProject.templContratto,
      idHostingPartner: filterSubProject.idHostingPartner,
      idSender: filterSubProject.idSender,
      page: filterSubProject.page + 1,
      maxElementPage: filterSubProject.maxElementPage,
      orderColumn: orderBy,
      tipoOrder: orderDirection,
    })
  }, []);

  const buttonStyle = {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      alignSelf: 'flex-end',
    },
  };


  const handleDownloadTmp = (id, isContract) => {
    console.log("idDownloadTMP",id);
    
    axios.get(configData.API_BASE+'/api/subProject/downloadTMP/'+id+"/"+isContract)
    .then(response => {
      console.log(response.data);
      //console.log(response.data.idHostingPartner.idPartner);
      //console.log(response.data.user.roles[0].id);
    })
    .catch(error => {
      //console.error(error);
      alert("errore nel processo di download del template");
    });

    axios({
      url: configData.API_BASE+'/api/subProject/downloadTMP/'+id+"/"+isContract,
      method: 'GET',
      responseType: 'blob',
  }).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      if(isContract){
        link.setAttribute('download', 'templateContratto.docx');
      }else{
        link.setAttribute('download', 'templateLA.docx');
      }
      
      document.body.appendChild(link);
      link.click();
  
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
  });
  };

  const handleEdit = (id) => {
    //setSelecterId(id);
    //console.log(id);
    
    axios.get(configData.API_BASE+'/api/subProject/'+id)
    .then(response => {
      //console.log(response.data);
      //console.log(response.data.idHostingPartner.idPartner);
      //console.log(response.data.user.roles[0].id);
      setFormData({
        idSubProject: response.data.idSubProject,
        idProgAppartenenza: response.data.idProgAppartenenza.idProject,
        cittaDest: response.data.cittaDest.idCitta,
        inizioMobilita: response.data.inizioMobilita,
        fineMobilita: response.data.fineMobilita,
        templLE: response.data.templLE,
        templContratto: response.data.templContratto,
        idHostingPartner: response.data.idHostingPartner.idPartner,
        idSender: response.data.idSender.idPartner,
      });
    })
    .catch(error => {
      console.error(error);
    });


	handleOpenDialog(true);
  };

	const handleDeleteConfirmation = (index) => {
    setDeleteRowIndex(index);
  }

	const handleDelete = (id) => {
    handleDeleteConfirmation(id);
  };

  const getCitta = () => {
    axios.get(configData.API_BASE+'/api/partners/citta')
      .then(response => {
        //console.log(response.data);
        setCittaData(response.data);
      })
      .catch(error => {
        console.error(error);
    });
  };

  const applicaFiltri = () => {
    setFilterSubProject({
      ...filterSubProject,
      page:0
    });
    filtra({
        idProgAppartenenza: filterSubProject.idProgAppartenenza,
        cittaDest: filterSubProject.cittaDest,
        inizioMobilita: filterSubProject.inizioMobilita,
        fineMobilita: filterSubProject.fineMobilita,
        templLE: filterSubProject.templLE,
        templContratto: filterSubProject.templContratto,
        idHostingPartner: filterSubProject.idHostingPartner,
        idSender: filterSubProject.idSender,
        page: 1,
        maxElementPage: filterSubProject.maxElementPage,
        orderColumn: orderBy,
        tipoOrder: orderDirection,
    });

    setListSubProjectExport({
        idProgAppartenenza: filterSubProject.idProgAppartenenza,
        cittaDest: filterSubProject.cittaDest,
        inizioMobilita: filterSubProject.inizioMobilita,
        fineMobilita: filterSubProject.fineMobilita,
        templLE: filterSubProject.templLE,
        templContratto: filterSubProject.templContratto,        
        idHostingPartner: filterSubProject.idHostingPartner,
        idSender: filterSubProject.idSender,
        page: -1,
        maxElementPage: filterSubProject.maxElementPage,
        orderColumn: orderBy,
        tipoOrder: orderDirection,
    })
  }

  const sort = (columnId, direction) => {

    setOrderBy(columnId);
    setOrderDirection(direction);
    setFilterSubProject({...filterSubProject, page:0})

    
    filtra({...filterSubProject,
      page: 1,
      orderColumn : columnId,
      tipoOrder : direction});
  }

  return (
    <Box>
      <Box mb={4}/>
      <Box ml={2} mr={2}>
        <div>
          <Box sx={{display: 'flex', flexWrap: 'wrap', maxWidth: "100%"}}>
              <Accordion sx={{width : "100%"}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <strong>Filtri di ricerca </strong>
                    <SearchIcon icon = "search"/>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                      <TextField label="Nome Progetto App." name="nomeProjectApp" variant="standard" value={filterSubProject.idProgAppartenenza} onChange={handleChange}  sx={{ minWidth: '78%'}}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel>Città</InputLabel>
                        <Select
                            name="cittaDest"
                            value={filterSubProject.cittaDest}
                            onChange={handleChange}
                            sx={{ maxWidth: '78%'}}
                        >
                        <MenuItem value="">Tutti</MenuItem>
                        {cittaData.map(citta => (<MenuItem value={citta.idCitta}>{citta.descrizione}</MenuItem>) )}
                        </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            variant = "standard"
                            name="inizioMobilita"
                            label="Data Inizio Mobilità"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            type="date"
                            value={filterSubProject.inizioMobilita}
                            onChange={handleChange}
                            fullWidth
                            sx={{ maxWidth: '78%', }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            variant = "standard"
                            name="fineMobilita"
                            label="Data Fine Mobilità"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            type="date"
                            value={filterSubProject.fineMobilita}
                            onChange={handleChange}
                            fullWidth
                            sx={{
                                maxWidth: '78%',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField label="Nome Hosting Partner" name="idHostingPartner" variant="standard" value={filterSubProject.idHostingPartner} onChange={handleChange} sx={{ minWidth: '78%'}}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField label="Nome Sender" name="idSender" variant="standard" value={filterSubProject.idSender} onChange={handleChange} sx={{ minWidth: '78%'}}/>
                    </Grid>
                    <Grid item xs={12}>
                      <br></br>
                      <Button variant="contained" color="primary" onClick={applicaFiltri}>
                        Filtra
                      </Button>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
          </Box>
        </div>

        <Box mb={4}/>
        <Paper sx={{ width: '100%' }}>
          <TableContainer  style={{maxHeight: "60vh"}}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead style={{position:"sticky", top:0, zIndex:99, backgroundColor: "white"}}>
                <TableRow>
                  <TableCell align="left" colSpan={100}>
                    <EnhancedTableToolbar rowCount={rows.length} onSelectAllClick={() => handleSelectAllClick(rows, rows.length, selected.length)} handleChange={handleChange} numSelected={selected.length} selezionati={selected}></EnhancedTableToolbar> 
                  </TableCell>
                  <TableCell align="right">
                  </TableCell>
                </TableRow>
                <TableRow>
                {columns.map((column) => (
                  <TableCell
                    sx={{
                      cursor: column.id !== 'action' ? 'pointer' : 'default',
                      position: 'sticky',
                      left: column.id === 'nomeProjectApp' ? 0 : 'auto',
                      zIndex: column.id === 'nomeProjectApp' ? 1 : 'auto',
                    }}
                    key={column.id}
                    align={column.align}
                    style={{
                      top: 57,
                      minWidth: column.minWidth,
                      backgroundColor: column.id !== 'nomeProjectApp' ? 'inherit' : 'white',
                    }}
                    onClick={() => {    
                        if (column.id !== 'actions' && column.id !== 'templLE' && column.id !== 'templContratto') {
                            sort(column.sort, orderDirection === 'asc' ? 'desc' : 'asc');
                        }
                    }}
                  >
                    <strong>{column.label}</strong>
                    {column.id !== 'actions' && column.id !== 'templLE' && column.id !== 'templContratto' ? (
                      <TableSortLabel
                        active={orderBy === column.sort}
                        direction={orderBy === column.sort ? orderDirection : 'asc'}
                      />
                    ) : null}
                  </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.idSubProject);
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.idSubProject} aria-checked={isItemSelected} selected={isItemSelected}>
                        {columns.map((column) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          const value = row[column.id];
                          if (column.id  === 'templContratto'){
                            return(
                                <TableCell>
                                    {(value == "" || value == null) ? <Button variant="contained"component="label" disabled> Download </Button> : <Button variant="contained"component="label" onClick={() => handleDownloadTmp(row.idSubProject, true)}> Download </Button>}
                                </TableCell>
                            );
                          }
                          if (column.id  === 'templLE'){
                            return(
                                <TableCell>
                                    {(value == "" || value == null) ? <Button variant="contained"component="label" disabled> Download </Button> : <Button variant="contained"component="label" onClick={() => handleDownloadTmp(row.idSubProject, false)}> Download </Button>}
                                </TableCell>
                            );
                          }
                          if (column.id === 'nomeProjectApp') { // Condizione per la colonna 'nome'
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                sx={{
                                  cursor: 'default', // Imposta il cursore sulla colonna 'nome'
                                  position: 'sticky',
                                  //overflow: 'hidden',
                                  //textOverflow: 'ellipsis',
                                  //whiteSpace: 'nowrap',
                                  left: 0,
                                  zIndex: 1,
                                  backgroundColor : 'white'
                                }}
                              >
                                {value}
                              </TableCell>
                            );
                          }
                          if (column.id === 'actions') {
                            return (
                                <TableCell key={column.id}>
                                    <div style={{ display: 'flex'}}>
                                    <Checkbox
                                      color="primary"
                                      checked={isItemSelected}
                                      onClick={(event) => handleClick(event, row.idSubProject)}
                                      inputProps={{
                                        'aria-labelledby': labelId,
                                      }}
                                    />
                                    <Button onClick={() => handleEdit(row.idSubProject)} size="small" style={{marginLeft : '0px'}}>
                                    <EditIcon icon="edit" color="blue" />
                                    </Button>
                                    <Button onClick={() => handleDelete(index)} size="small" style={{marginLeft : '0px'}}>
                                    <DeleteIcon color="error"/>
                                    </Button>
                                  { /* <Button onClick={() => handlePage(row.idProgAppartenenza)} size="small" style={{marginLeft : '0px'}}><ManageSearchIcon color="action"/></Button> */}
                                    </div>
                                </TableCell>
                            );
                          }
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number' ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 50]}
            component="div"
            count={listSubProject.countSubProject}
            //rowsPerPage={rowsPerPage}
            //page={page}
            rowsPerPage={filterSubProject.maxElementPage}
            page={filterSubProject.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        {(formData.idSubProject == '' || formData.idSubProject == null) ? <DialogTitle>Aggiungi un Sotto-Progetto</DialogTitle> : <DialogTitle>Modifica un Sotto-Progetto</DialogTitle>}
        <DialogContent>
            <FormSubProject formData={formData} setFormData = {setFormData} error = {error} setError = {setError} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annulla</Button>
          <Button onClick={addAndCloseDialog} color="primary">
            Salva
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteRowIndex !== null} onClose={handleDeleteCancel}>
        <DialogTitle>Elimina riga</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sei sicuro di voler eliminare questa riga?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Annulla
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Conferma
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={deleteRowIndexMultiple !== null} onClose={handleDeleteCancelMultiple}>
        <DialogTitle>Elimina le righe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sei sicuro di voler eliminare le righe selezionate?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancelMultiple} color="primary">
            Annulla
          </Button>
          <Button onClick={handleDeleteSelected} color="primary">
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}