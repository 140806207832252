import 'bootstrap/dist/css/bootstrap.min.css';
import './TestForm.css';
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import axios from "axios";
import * as React from "react";
import {useEffect} from "react";
import {configData} from "./configData/configData";

axios.defaults.headers.common['Authorization']=localStorage.getItem('token');
axios.defaults.headers.common['Authorization']=localStorage.getItem('id');

function FormRisposta(props) {

    const componentRef = React.useRef(null);
    const [formData, setFormData] = React.useState([]);

    /*
    useEffect(() => {
        var token=localStorage.getItem('token');

        if(token){
            axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
            axios.get(configData.API_BASE+"/api/forms/submit/dettagli/1/"+localStorage.getItem('id')).then(
                res=>{
                    const post=res.data;
                    console.log(post);
                    setFormData(post);
                }
            )
        }else{
            axios.defaults.headers.common['Authorization']=null;
        }},[])

     */







    /*
    const style ={
        //backgroundColor:"whitesmoke",
    }

     */



    return (


    <div>
        <Form
            schema={props.schema}
            uiSchema={props.uiSchema}
            formData={props.formData}
            validator={validator}
            showErrorList={false}
            disabled={true}
            readonly
            noValidate
        />
    </div>


);
}

export default FormRisposta;