import React, { Component } from "react";
import * as PropTypes from "prop-types";
import FormRisposta from "./FormRisposta";
import Card from "@mui/material/Card";
import './Risposta.css';
import CardContent from "@mui/material/CardContent";
import { useParams } from "react-router-dom";
import axios from "axios";
import {configData} from "./configData/configData";
import {CardActions} from "@mui/material";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}


axios.defaults.headers.common['Authorization']=localStorage.getItem('token');
axios.defaults.headers.common['Content-Type']=localStorage.getItem('id');

FormRisposta.propTypes = {uiSchema: PropTypes.any};

function PrintElem(elem)
{
   
    document.getElementById(elem).className = "printCardForm";
    window.print();
    document.getElementById(elem).className = "cardForm";
   
    /*
    let printContents = document.getElementById(elem).innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents; 
    */

    return true;

}

class Risposta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: {},
            uiSchema: {},
            formData:{}
        };
    }
    componentDidMount() {
        //console.log("id_user", this.props.params.userId);
        //console.log("id_form", this.props.params.formId);

        var token=localStorage.getItem('token');

        if(token){
            axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
            axios.get(configData.API_BASE+"/api/forms/"+this.props.params.formId+"/blocked=true").then(
                res=>{
                    const jsonSchema=JSON.parse(res.data.jsonschema);
                    const uiSchema=JSON.parse(res.data.uischema);
                    //console.log(jsonSchema);
                    //console.log(uiSchema);
                    this.setState({schema:jsonSchema,uiSchema:uiSchema});
                }
            )
            let id=this.props.params.userId;
            if (this.props.params.userId==undefined){
                id=localStorage.getItem('id');
            }
            axios.get(configData.API_BASE+"/api/forms/submit/dettagli/"+this.props.params.formId+"/"+id).then(
                res=>{
                    const get=res.data;
                    this.setState({formData:get});
                }
            )

        }else{
            axios.defaults.headers.common['Authorization']=null;
        }

        const getHeadings = () => {
            return Object.keys(this.state.formData);
        }

    }





    render() {
        const useStyles = {

        }
        return (
            <div>
                <Card className="noPrint" >
                    <CardActions>
                        <button onClick={()=>PrintElem("form")}>Stampa</button>
                    </CardActions>
                </Card>

            <Card>
                <CardContent className="cardForm" id="form">
                    <FormRisposta schema={this.state.schema} uiSchema={this.state.uiSchema} chiave={this.getHeadings} formData={this.state.formData} />

                </CardContent>
            </Card>

            </div>




        );
    }
}

export default withParams(Risposta);