import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import "./login.css"
import axios from 'axios'
import { configData } from '../configData/configData';
import logoValuetech from '../image/logoValuetech.png';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {useEffect} from "react";

export default function Login() {
  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  const [values, setValues] = React.useState({
    amount: '',
    fullName:'',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
    exists:'',
     errors: {
      fullName: '',
      email: '',
     }
  });
  const [reset, setReset] = React.useState(false);
  const [resetMail, setResetMail] = React.useState("");
  const [resetError, setResetError] = React.useState("");
  const [resetAlert, setResetAlert] = React.useState(false);
  const [enabled, setEnabled] = React.useState(true);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  

  const handleSubmit = () => {
    const utente={
      username: values.fullName,
      password: values.password
    }
    //console.log("utente",utente);
 
  
    axios.post(configData.API_BASE+'/api/auth/signin',utente).then(
      res=>{
        //console.log(res);
        //console.log(res.data);
       // ruolo=res.data.roles;
       // console.log(ruolo);
        if(res.status==200){ 
          localStorage.setItem("token",res.data.accessToken)
          localStorage.setItem("id",res.data.id)
          localStorage.setItem("ruolo", res.data.roles)
          if(res.data.roles=="ROLE_USER"){
            document.location.href = configData.SITE_URL+"/listaForm";
          }else if(res.data.roles=="ROLE_MODERATOR" || res.data.roles=="ROLE_ADMIN"){
            document.location.href = configData.SITE_URL+"/listaFormBackoffice";
          }
        }
      } 
    ).catch(error => {
      console.log(error.response.data.message);
      let errors = values.errors;
      if(error.response.data.message=="Questo Username non è presente nel sistema"){
          setValues({...values, password:"", exists:error.response.data.message});
      }
      if(error.response.request.status==401){
           setValues({...values, password:"", exists:"Password errata"});
      }

    }) 
    
  }

    const handleUsername=(event)=>{
      setValues({...values,fullName: event.target.value});
    }
  
    const handlePassword=(event)=>{
      setValues({...values,password: event.target.value});
    }

    const onChangeResetMail=(event) => {
      setResetMail(event.target.value);
      setResetError(validEmailRegex.test(event.target.value) || event.target.value === "" ? "" : "Formato email errato");
    }

    const handleReset=() => {
      if(resetError!==''){
          return;
      }
      setEnabled(false);
        setResetAlert(true);
        axios.post(configData.API_BASE + "/api/auth/resetPassword?email=" + resetMail).then(res => {

        });


    }
    const showReset = () => {
        setReset(true);
        setEnabled(true);
        setTimeout(() => {
            window.scrollTo(0,99999)
        }, 1)
    }


  return (
   <div>
    
    <div className='fill'>
     <img 
        width={'20%'}
        height={'20%'}
        style={{marginTop:"35px", marginLeft:"40%"}}
        src={logoValuetech}
        alt="Logo" />
     </div>
      <Card className ="Log" sx={{maxWidth:400}} 
        style={{
          boxShadow: "1px 3px 5px grey",
          borderRadius: "6px",
          padding: '1em',
          margin:'50px auto',
          textAlign:'center'}}  
      >
        <CardContent >
          <Typography gutterBottom variant="h5" component="div" >
            Login
          </Typography>
          <Box onChange={handleUsername}
             component="form"
             sx={{ m: 1, width: '25ch' }}
             noValidate
             autoComplete="off"
             style={{ margin:'auto'}}
            >
           <TextField id="outlined-basic" label="Username" variant="outlined" />
          </Box>
          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password"  onChange={handlePassword}>Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password} 
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        </CardContent>
        <CardActions style={{justifyContent: "center"}}>
            <Button href={configData.SITE_URL+"/registrami"}>Registrati</Button>
            <hr />
            <Button type="submit" variant="contained" onClick={() => handleSubmit()}>Accedi</Button>
        </CardActions>
        <span id="forgot" className='falseLink' onClick={showReset}>Ho dimenticato la password</span>
            <div className="Color">
              {values.exists != "" ? values.exists : ""}  
            </div>
            <pre></pre>
     </Card>
     {reset ? <Card className ="Log" sx={{maxWidth:400}} 
        style={{
          boxShadow: "1px 3px 5px grey",
          borderRadius: "6px",
          padding: '1em',
          margin:'50px auto',
          textAlign:'center'}} 
      >
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" >
            Reset Password
          </Typography>
          <Typography gutterBottom variant="h7" component="div" >
            Inserisci qui la tua mail per procedere con il reset della password
          </Typography>
          <TextField error={resetError} onChange={onChangeResetMail} id="outlined-basic" label="Email" variant="outlined" />
          {resetError !== '' ? <div className='error'>{resetError}</div> : ''}
        </CardContent>
        <CardActions style={{justifyContent: "center"}}>
        <Button disabled={!enabled} type="submit" variant="contained" onClick={handleReset}>Reset Password</Button>
        </CardActions>
     </Card>
     : ""}

      <Snackbar open={resetAlert}  anchorOrigin={{vertical:'bottom',horizontal:'center'}}  autoHideDuration={5000} onClose={() => {
        setResetAlert(false);
        setReset(false);
      }}> 
        <Alert severity="success" sx={{ width: '100%' }}>
          Richiesta inviata. Se l'indirizzo è presente nel sistema, riceverai una mail con le istruzioni per resettare la password. 
        </Alert>
      </Snackbar>
     
     </div> 
    );
}