import React, { useState, useEffect } from 'react';
import {configData} from "./configData/configData";
import {useParams} from "react-router-dom";

import axios from "axios";

function VisualizzaDownload() {

    let { formSubmitId,metaKey } = useParams();
    const [filename, setFilename] = useState("");

    useEffect(() => {
        var token=localStorage.getItem('token');
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
        axios({
            url: configData.API_BASE+"/api/forms/submitdata/info/"+formSubmitId+"/"+metaKey, //your url
            method: 'GET',
        }).then( response => {
            console.log(response)
            setFilename(response.data.filename)
        })

    }, [])


    function downloadFile(){
        var token=localStorage.getItem('token');


        /*
        if(token){
            axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
            axios.get(configData.API_BASE+"/api/forms/submitdata/download/"+formSubmitId+"/"+metaKey).then(
                res=>{
                    console.log(res.body);

                })
        }

         */

        if(token){
            axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
        axios({
            url: configData.API_BASE+"/api/forms/submitdata/download/"+formSubmitId+"/"+metaKey, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }).catch((response) => {
            alert("file non trovato");
        });
        }


    }

    return (
    <div style={{textAlign: 'center', marginRight: '30%', marginLeft: '30%', marginTop: 20}}>
        <div>
            Stai per scaricare:
        </div>
        <div style={{fontWeight: 'bold'}}>
            {filename}
        </div>
        <button onClick={downloadFile}>Scarica ora</button>
    </div>);
}



export default VisualizzaDownload;