import React, { useState } from 'react';
import { configData } from './configData/configData';
import axios from 'axios';
import {
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  AppBar,
  Toolbar,
  Card,
  CardContent,
  CardActions,
  IconButton,
  InputAdornment,
} from '@mui/material';
import FormHelperText from "@material-ui/core/FormHelperText";
import { useEffect } from 'react';

function RegistrationForm(props) {
  const id = props.id;
  const formProject = props.formProject;
  const setFormProject = props.setFormProject;
  const error = props.error;
  const setError = props.setError;

  const handleChange = (event) => {
    setFormProject({
      ...formProject,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeAttivo = (event) => {
    setFormProject({
      ...formProject,
      attivo: event.target.value,
    });
  };

  useEffect(() => {
    var token=localStorage.getItem('token');
  
    if(token){
      axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    }else{
      axios.defaults.headers.common['Authorization']=null;
    }
  }, []);


  return (
    <Box>
      <Box display="flex" justifyContent="center" p={2}>
        <form>
          <Grid item xs={12}>
            <strong>Dati Progetto</strong>
          </Grid>
          <br></br>
          <Grid container spacing={2}>
          <Grid item xs={12}>
              <TextField
                name="cup"
                label="CUP"
                fullWidth
                value={formProject.cup}
                onChange={handleChange}
                error={error.cup}
                helperText={error.cup ? 'Inserisci un cup' : ''}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="nomeProject"
                label="Nome Progetto"
                fullWidth
                value={formProject.nomeProject}
                onChange={handleChange}
                error={error.nomeProject}
                helperText={error.nomeProject ? 'Inserisci un nome' : ''}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="anno"
                label="Anno del progetto"
                fullWidth
                value={formProject.anno}
                onChange={handleChange}
                error={error.anno}
                helperText={error.anno ? 'Inserisci un anno' : ''}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Stato del Progetto</InputLabel>
                <Select
                  name="attivo"
                  error={error.attivo}
                  value={formProject.attivo}
                  onChange={handleChangeAttivo}
                  helperText={error.attivo ? 'Seleziona uno stato del progetto' : ''}
                  required
                  disabled = {formProject.idProject == "" ? true : false}
                >
                  <MenuItem value={true}>
                    Attivo
                  </MenuItem>
                  <MenuItem value={false}>
                    Non Attivo
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="codiceProject"
                label="Codice Progetto"
                fullWidth
                value={formProject.codiceProject}
                onChange={handleChange}
                error={error.codiceProject}
                helperText={error.codiceProject ? 'Inserisci un Codice Progetto' : ''}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="referenteProject"
                label="Referente Progetto"
                fullWidth
                value={formProject.referenteProject}
                onChange={handleChange}
                error={error.referenteProject}
                helperText={error.referenteProject ? 'Inserisci un Referente Progetto' : ''}
                required
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
}

export default RegistrationForm;
